<template>
	<div id="app" v-if="isLogin">
		<Head @custom-event="ChangeAccount" />
		<router-view ref="routerView" />
	</div>
	<div v-else class="connect_wallet">
		<div class="logo_box">
			<img src="./assets/logo.png" class="logo">
		</div>
		<div class="box">
			<img src="./assets/pow_icon.png" class="logo2">
			<div class="title1">去中心化AI雲算力平台</div>
			<div class="title2">Decentralized AiPool Power Platform</div>
			<van-button class="default_btn" @click="connect_wallet" :class="{'loading_btn':connect_loading}">
				<van-loading color="#151515" size="24" v-if="connect_loading"/>
				<span v-else>{{ $t('appview.t2') }}</span>
			</van-button>
		</div>
	</div>
</template>
<script>
import Head from '@/components/Head.vue';
import {getAccount,checkNetWork,switchNetwork,connectWallet,signMessage,encryptAddress,getLpData,getNftInfo,totalPower} from '@/utils/eth_methods.js';
import { Notify } from 'vant-green';
export default {
	components: {
		Head: Head,
	},
	data() {
		return {
			isLogin:false,
			connect_loading:false,
		};
	},
	mounted() {
		checkNetWork().then(async (isNetWork)=>{
			if(!isNetWork){
				//切换网络
				await switchNetwork()
			}
			const accounts = await getAccount();
			const account = localStorage.getItem("userAccount")
			const token = localStorage.getItem("token")
			if(account && token && accounts.length>0 && accounts[0] == account){
				this.$store.state.account = account;
				this.$store.state.token = token;
				this.connect_loading = true;
				await this.init();
			}
		})
	},

	
	methods: {
		connectWallet,
		signMessage,
		getLpData,
		getNftInfo,
		totalPower,
		ChangeAccount(account) {
			console.log("account changed:",account)
		},
		async init(){
			Promise.all([this.get_chain_info(),this.pioneer()]).then(result=>{
				if(this.$store.state.config && this.$store.state.config.Token){
					const task = [
						this.getNftData(),
						this.getTotalPower()
					];
					
					if(this.$store.state.parentInviteCode!=''){
						task.push(this.queryRelease(),this.queryIncome(), this.assetData(), this.queryUnregister());
					}
					Promise.all(task).then(result2=>{
						setTimeout(()=>{
							this.isLogin = true
						},1500)
					}).catch(error=>{
						Notify({
							type: 'danger',
							message: 'Network error',
						})
						console.error('初始化錯誤:', error);
						this.connect_loading = false;
					})
				}else{
					Notify({
						type: 'danger',
						message: 'Network error',
					})
					this.connect_loading = false;
				}
				
			}).catch(error=>{
				Notify({
					type: 'danger',
					message: 'Network error',
				})
				console.error('初始化錯誤:', error);
				this.connect_loading = false;
			})
		},
		async getTotalPower(){
			this.$store.state.totalPower = await this.totalPower();
			console.log('全網算力',this.$store.state.config.totalpower + this.$store.state.totalPower)
			return this.$store.state.totalPower;
		},
		async pioneer(){
			const result = await this.$api.request('/api/cst/pioneer');
			if(result.code==200){
				this.$store.state.parentInviteCode = result.data.fatherAddress;
				this.$store.state.myInviteCode = result.data.code
				this.$store.state.level = result.data.level
				this.$store.state.invitedList = result.data.invitedList?result.data.invitedList:[];
				this.$store.state.invitedCount = result.data.invitedCount;
			}
			return result.data;
		},
		async get_chain_info(){
			const result = await this.$api.request('/api/cst/get_chain_info');
			if(result.code==200){
				this.$store.state.config = result.data
			}
			return result.data;
		},
		
		//连接钱包
		async connect_wallet(){
			const isNetWork = await checkNetWork();
			if(!isNetWork){
				//切换网络
				await switchNetwork();
			}

			if(this.connect_loading) return;
			this.connect_loading = true;

			//连接钱包，获取账户
			this.connectWallet().then((account)=>{
				//签名
				this.signMessage(account).then(async (sign)=>{
					await this.getToken(account,sign);
				}).catch((error)=>{
					Notify({ type: 'danger', message: this.$t('appview.t3') });
					this.connect_loading = false;
				})
			})
		},
		
		/**
		 * 获取Token
		 *
		 * @param account 账户地址
		 * @param sign 签名
		 * @returns Promise<void>
		 */
		async getToken(account,sign){
			try {
				const result = await this.$api.postData("/api/auth", {
					wallet_address: account,
					public_key: '',
					signature: sign,
					message: account,
					hash: encryptAddress(account)
				});
				if (result.code == 200) {
					const token = result.token;
					this.token = token;
					this.$store.state.token = token;
					localStorage.setItem("token", token);

					this.$store.state.account = account;
					localStorage.setItem("userAccount", account);
					
					await this.init();
					// this.connect_loading = false;
				}
			} catch (error) {
				Notify({ type: 'danger', message: 'Network error' });
				this.connect_loading = false;
			}
		},
		//获取资产
		async assetData() {
			this.$store.state.LpData = await this.getLpData();
			console.log("LpData:",this.$store.state.LpData)
			return this.$store.state.LpData;
		},
		async getNftData(){
			this.$store.state.nftInfo = await this.getNftInfo();
			console.log("NFT:",this.$store.state.nftInfo)
			return this.$store.state.nftInfo;
		},
		//查询收益
		async queryIncome() {
			try {
				const result = await this.$api.request("/api/cst/asset", {}, "GET");
				if (result.code == 200) {
					this.$store.state.income = result.data;
				}
			} catch (error) {
				console.log(error);
			}
			return this.$store.state.income;
		},
		//查询待释放收益
		async queryRelease() {
			try {
				const result = await this.$api.request("/api/cst/to_be_released", {}, "GET");
				if (result.code == 200) {
					this.$store.state.released = result.data;
				}
			} catch (error) {
				console.log(error);
			}
			return this.$store.state.released;
		},
		//查詢撤銷狀態
		async queryUnregister(){
			try {
				const result = await this.$api.request("/api/cst/get_unregister_info", {}, "GET");
				if (result.code == 200) {
					this.$store.state.unregister_info = result.data;
				}
			} catch (error) {
				console.log(error);
			}
			return this.$store.state.unregister_info;
		},
	}
};
(function () {
	function setRemUnit() {
		const docEl = document.documentElement;
		const width = docEl.clientWidth;

		// 设置设计稿宽度，这里假设设计稿宽度为375px
		const designWidth = 375;

		// 设置根元素字体大小为 (当前屏幕宽度 / 设计稿宽度) * 初始字体大小
		docEl.style.fontSize = (width / designWidth) * 12 + 'px';
	}

	// 初始化时设置一次
	setRemUnit();

	// 窗口大小改变时重新设置
	window.addEventListener('resize', setRemUnit);
})();
</script>
<style scoped lang="scss">
.connect_wallet{
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	.logo_box{
		flex-grow: 1;
		display: flex;

	}
	.logo{
		width: 60%;
		margin: auto;
	}
	.logo2{
		height: 22px;
	}
	.box{
		margin: 0 15px 50px;
		height: 200px;
		background-color: #262626;
		border-radius: 20px;
		text-align: center;
		padding: 40px 53px;
	}
	.title1{
		margin-top: 40px;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0px;
		color: #999999;
	}
	.title2{
		margin-top: 13px;
		margin-bottom: 48px;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0px;
		color: #999999;
	}
}
</style>
<style lang="scss">
html {
	font-size: 12px;
	background: #151515;
	overflow-x: hidden;
	/* 设置一个初始值，防止页面闪烁 */
}

body {
	margin: 0;
	padding: 0;
	background: url('./assets/background.png') no-repeat;
	background-size: 100%;
	min-height: 100vh;
}

.el-message {
	z-index: 9999999 !important;
}

.el-loading-spinner i,
.el-loading-spinner .el-loading-text {
	color: #fff !important;
}

.default_btn {
	background-image: linear-gradient(0deg, 
		#ff6318 0%, 
		#ff9b13 47%, 
		#ffd30d 100%);
	border-radius: 12px;
	font-weight: 500;
	color: #000000 !important;
	width: 100%;
	transition: 300ms;
	user-select: none;
	border: none !important;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px !important;
	height: 40px !important;
    opacity: .9 !important;

	&:active {
		transform: scale(0.98);
		opacity: 0.85;
	}

	&.loading_btn{
		width: 50px;
		// background: rgb(120 120 120);
		margin: auto;
	}
}
.cancel{
	background: #151515;
    border: 1px solid #ff9c12 !important;
    color: #ff9c12 !important;
	height: 38px !important;
}
.hollow_btn{
	width: 107px;
	height: 32px;
	border-radius: 6px;
	border: solid 1px #ffa511;
	font-size: 14px !important;
	text-align: center;
	line-height: 32px;
	font-size: 14px !important;
	font-weight: 500;
	color: #ffa511 !important;
	margin: auto;
	transition: 300ms;
	user-select: none;

	&:active {
		transform: scale(0.98);
		opacity: 0.85;
	}
}
.van-dialog {
	background: url(./assets/info_bj.png) no-repeat;
	// background: url(./assets/background.png) no-repeat;
	background-size: 100%;
	background-color: #151515!important;
	// background-image: linear-gradient(90deg, #87ecfa 0%, #f4b2ea 50%, #fac29c 74%, #ffd24d 100%);
	background-size: 100%;
	border-radius: 20px;
	color: #fff;
	width: 85% !important;
	min-height: 440px !important;

	display: flex;
    flex-direction: column;
}
.van-dialog::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit; /* 使用与父元素相同的背景 */
  filter: blur(8px); /* 添加模糊效果 */
  z-index: -1; /* 将伪元素放在内容后面 */
  border-radius: inherit;
}
.van-dialog .van-dialog__content{
	max-height: 60vh;
    overflow-y: auto;

	flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.van-dialog .van-dialog__header{
	padding-top: 20px;
	padding-bottom: 14px;
}
.van-dialog b {
	color: #fed057;
}

.van-dialog i {
	color: #fed057;
}
.van-dialog-confirm {
	background-image: linear-gradient(90deg, #87ecfa 0%, #f4b2ea 50%, #fac29c 74%, #ffd24d 100%);
}
.dialog_box {
	text-align: left;
	line-height: 24px;
	padding: 0px 18px 0px 20px;
	color: #e2e2e2;
	font-size: 13px;

	display: flex;
    flex-direction: column;
    flex-grow: 1;
	.content_box{
		flex-grow: 1;
	}
	.btn_box{
		padding: 40px 0 30px;
	}
}
.van-button{
	border-radius: 12px !important;
	padding: 0 !important;
}

.van-cell{
	border: none !important;
    background: none!important;
    padding: 0!important;

	input{
		color: #fff!important;
	}
}

.van-field__button{
	width: 60px;
	height: 32px;
}

.my_sticky{
	position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
}
.van-tabs__nav{
	background-color: #151515 !important;
}
.van-tabs__line{
	background-color: #ff9c12 !important;
}
.van-tab{
	color: #999 !important;
}
.van-tab--active{
	color: #fff !important;
}

.van-notify {
  z-index: 3000 !important; /* 覆盖 Notify 的 z-index */
}
</style>
