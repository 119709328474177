import axios from 'axios';
import Vue from 'vue';

let is401 = false;
// 创建一个 axios 实例
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || '', // 使用环境变量配置 API 基础 URL
    headers: {
        'Content-Type': 'application/json',
    },
});
// 请求拦截器，动态设置 token
apiClient.interceptors.request.use(config => {
    // console.log('请求拦截器', config);
    // if(config.params.isLoading){
    //     Vue.prototype.$toast.loading({
    //         message: '玩命加载中...',
    //         forbidClick: true,
    //         duration: 0
    //     })
    // }
   
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器，验证 token 是否有效
apiClient.interceptors.response.use(response => {
    // console.log('响应拦截器', response);
    Vue.prototype.$toast.clear();
    return response;
}, error => {
    Vue.prototype.$toast.clear();
    console.log(error);
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (!is401) {
            is401 = true;
            Vue.prototype.$toast(error.response.data.message || '登录过期，请重新登录');
            setTimeout(() => {
                is401 = false; // 重置标志位
                window.location.reload(); // 刷新页面
            },1500)
        }
        // 如果 token 无效或账号被冻结，执行退出登录操作
        localStorage.removeItem('token');
        this.$store.token = '';
    } 
    return Promise.reject(error);
});

export function request(func, data={}, method='get') {
    method = method.toLowerCase();
    if (method === 'get') {
        return apiClient.get(func, { params: data }).then(response => response.data).catch(error => {
            console.log('Error fetching data:', error);
            throw error;
        });
    } else if (method === 'post') {
        return apiClient.post(func, data).then(response => response.data).catch(error => {
            console.log('Error posting data:', error);
            throw error;
        });
    }
}
// 定义接口请求函数
export function fetchData(func) {
    return apiClient.get(func)
        .then(response => response.data)
        .catch(error => {
            console.log('Error fetching data:', error);
            throw error;
        });
}

export function postData(func, data) {
    return apiClient.post(func, data).then(response => response.data).catch(error => {
        console.log('Error posting data:', error);
        throw error;
    });
}
