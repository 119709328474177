import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	"zh-hant": {
		head: {
			t1: "連接錢包",
			t2: "錢包地址複製成功",
			t3: "斷開連接",
			t4: "是否斷開錢包連接？",
			t5: "取消",
			t6: "確認",
		},
		methods:{
			t1: "未檢測到錢包，請先安裝錢包",
			t2: "切換網絡失敗，請刷新頁面重試",
			t3: '未獲取到錢包賬戶',
			t4: '請刷新頁面重新簽名授權',
			t5: '刷新頁面',
			t6: '關閉Dapp',
			t7: "代幣添加成功",
			t8: '用戶拒絕添加',
			t9: '添加代幣出錯',
		},
		appview:{
			t1: '去中心化AI雲算力平台',
			t2: '連接錢包',
			t3: '簽名失敗',
		},
		homeview:{
			t1: "PowerLP是DeFund基金會推出的一項全新雲算力業務，專注於構建基於AI雲算力的全新金融生態系統。我們秉承DeFund基金會的核心理念，致力於將區塊鏈技術、AI智能與雲算力深度融合，打造成為Web3去中心化AI雲算力引領者。為全球用戶提供前所未有的投資體驗和價值增長機會。PowerLP以其創新的技術架構、高效的算力服務和社區驅動的模式，正逐步成為去中心化金融領域的一顆璀璨新星。",
			t2: "查看全部",
			t3: "上級邀請碼",
			t4: "我的邀請碼",
			t5: "分享推廣",
			t6: "我的資產",
			t7: "我的帳號",
			t8: "質押數",
			t9: "累計兌換",
			t9_1: "持倉數",
			t10: "上次兌換",
			t10_1: "質押",
			t10_2: "算力",
			t11: "兌換算力",
			t11_1: "撤銷",
			t12: "我的收益",
			t13: "可提現數量",
			t14: "已產出",
			t15: "已提現",
			t15_1: "一鍵提現",
			t16: "邀請列表",
			t17: "用戶",
			t18: "團隊業績(T)",
			t19: "等級",
			t20: "暫無數據",
			t21: "推薦關係綁定",
			t22: "推薦人邀請碼",
			t23: "請輸入邀請碼",
			t23_1: "推薦人綁定後無法解綁，請核對邀請碼是否正確",
			t24: "取消",
			t25: "確認",
			t26: "質押Pow",
			t27: "質押數量",
			t28: "請輸入質押數量",
			t29: "可用數量",
			t30: "兌換算力包",
			t31: "兌換數量",
			t32: "本次最低兌換：",
			t33: "可兌換",
			t34: "膨脹算力",
			t35: "膨脹係數",
			t36: "請輸入兌換數量",
			t37: "一鍵提現",
			t38: "提現地址",
			t39: "可提現數量",
			t39_1: "最低提現",
			t39_2: "兌換數量錯誤",
			t39_3: "最小兌換數量為:",
			t40: "手續費",
			t41: "到帳收益",
			t42: "提現",
			t43: "請輸入質押數量",
			t44: "質押成功",
			t45: "數量不足",
			t46: "複製成功",
			t47: "可用數量不足，請先質押",
			t48: "兌換成功",
			t49: "請輸入兌換數量",
			t50: "提現成功",
			t51: "提現失敗",
			t52: "網絡錯誤，請重試",
			t53: "提現數量需要大於0",
			t54: "全部",
			t55: "鏈上數據執行中，請稍候刷新",
			t56: "全網算力：",
		},
		logout:{
			t0: "帳號撤銷",
			t1: "是否確認撤銷？請仔細閱讀下列說明！",
			t2: "<b>退還資產：</b>退還資產 = 總投資(質押數+累計兌換) – 已提現數量 – 違約金。",
			t3: "<b>違約金計算：</b>退還資產金額的30%將作為違約金扣除。",
			t4: "<b>退還失效：</b>赎回的資產，將在24小時自動轉入您的錢包。",
			t5: "<i>提交贖回申請將會在7個工作日內完成審核，審核期間算力包正常釋放，審核通過後未提現的將自動提現、算力包失效、网体被注销。請慎重操作！</i>",
			t6: "取消",
			t7: "確認",
			t8: "操作成功，等待管理員審核",
			t9: "操作失敗，請重試",
			t10: "預計可退回：",
			t11: "違約金預計：",
			t12: "知道了",
			t13: "取消撤銷申請",
			t14: "取消成功",
			t15: "是否確認取消撤銷申請？",
			t16: "撤銷申請被拒絕，查看原因",
			t17: "撤銷申請審核中，查看詳情",
			t18: "您的撤銷申請被拒絕，拒絕理由：",
			t19: "該彈窗消息僅顯示一次，閱讀後將不再顯示",
			t20: "撤銷申請正在審核中，預計7個工作日內完成審查，審核期間算力包正常釋放，審核通過後未提現的將自動提現、算力包失效、网体被注销。",
			t21: "已閱",
			t22: "申請時間：",
			t23: "無法撤銷，當前預計可退回Pow數量小於等於0",
			t24: "總產出已經大於總投資數量，無需撤銷帳號，請選擇直接提現收益即可",
		},
		nft:{
			t1: "NFT質押分紅",
			t2: "可質押",
			t3: "已質押",
			t4: "質押",
			t5: "撤回質押",
			t6: "本週收益分紅",
			t7: "提取收益",
			t10: "質押NFT可分紅跨鏈手續費",
			t11: "每週六早6點領取分紅",
			t12: "每週分紅僅可領取一次，過期無法領取"
		},
		nft_info:{
			t0: "NFT質押說明",
			t1: "您可將NFT質押到合約中，以此來獲得手續費分紅",
			t2: "<b>什麼是手續費分紅？</b>用戶每次使用跨鏈橋跨鏈Pow代幣时都會收取相應的手續費，質押NFT即可瓜分此全部手續費。",
			t3: "<b>分紅領取時間？</b>每週六早9點開始分紅，每7天可領取一次。",
			t4: "<b>注意事項：</b>如本週分紅未領取則過期無法領取，請務必在7天內領取分紅獎勵。"
		},
		pledgeNft:{
			t0: "NFT質押",
			t1: "質押數量",
			t2: "請輸入質押數量",
			t3: "全部",
			t4: "可用數量",
			t5: "取消",
			t6: "確認質押",
			t7: "授權失敗",
			t8: "質押成功",
			t9: "質押失敗",
			t10: "撤回質押成功",
			t11: "撤回質押失敗",
			t12: "領取分紅成功",
			t13: "領取分紅失敗",
			t14: "撤回數量不足",
			t15: "暫無分紅收益",
			t16: "NFT數量不足",
		},
		release_status:{
			t1: "產出中",
			t2: "產出完畢",
		},
		release_type: {
			t1: "靜態",
			t2: "網體加速",
			t3: "節點加速",
		},
		swap_status: {
			t1: "申請中",
			t2: "被拒絕",
			t3: "許可上鏈",
			t4: "已上鏈",
		},
		level:{
			t1: "普通",
			t2: "初級",
			t3: "中級",
			t4: "高級",
			t5: "超級",
			t6: "創世",
			t7: "至尊",
		},
		pow_info:{
			t0: "PowerLP說明",
			t1: "<b>什么是PowerLP：</b><br />PowerLP是DeFund基金會推出的一項全新雲算力業務，專注於構建基於AI雲算力的全新金融生態系統。我們秉承DeFund基金會的核心理念，致力於將區塊鏈技術、AI智能與雲算力深度融合，打造成為Web3去中心化AI雲算力引領者。為全球用戶提供前所未有的投資體驗和價值增長機會。PowerLP以其創新的技術架構、高效的算力服務和社區驅動的模式，正逐步成為去中心化金融領域的一顆璀璨新星。",
			t2: "<b>POW代幣：</b><br />代幣全名為：<b>AiPool Power Token</b> <br />發行總量：<b>2億枚</b> <br />發行公鏈：<b>3TChat</b> <br />發行價格：<b>0.1 USDT</b> <br />捐贈數量：<b>10%（2000萬枚）</b> <br />挖礦鑄造：<b>90%（1.8億枚）</b>",
			t3: "添加Pow到錢包",
			t4: "<i>點擊上方按鈕快速將Pow代币添加至錢包。</i>",
		},
		deposit_info: {
			t0: "兌換說明",
			t1: "<b>投資規則：</b><br/>1、每個同時僅能持有一份算力包;<br/>2、複投時，需大于上次投資的10%，不能減小；<br/>3、資產包全部產出完，網體將保留7天，不復投將清除LP算力網絡，只能重新參與；<br/>4、重新參與將生成新的網體，建立新的算力網絡；<br/>5、最少質押數量為51.2T算力;",
			t2: "<b>质押規則：</b><br/>1、每10個Pow代幣對應1T的算力;<br/>2、您需要先將Pow代幣質押到合約中，然後進行兌換；",
			t3: "兌換數量",
			t4: "膨脹係數",
		},
		level_info: {
			t0: "節點等級說明",
			t1: "<b>投資人加入網體後，開始推廣，達到對應指標後即可獲得對應等級。節點分為6個等級，滿足條件即可自動升級，並獲得對應的加速產出</b>",
			t2: "<b>初級節點：</b><br/>至少2個直推，且每個直推至少分別完成3,000T算力，自身傘下（含自身）總算力 >= 10,000T，持倉數 >= 100 T，傘下累計業績算力日產出加速15%",
			t3: "<b>中級節點：</b><br/>至少2個直推，且每個直推或其傘下成員各有一個初級節點，自身傘下（含自身）總算力 >= 50,000T，持倉數 >= 100 T，傘下累計業績算力日產出加速20%",
			t4: "<b>高級節點：</b><br/>至少2個直推，且每個直推或其傘下成員各有一個中級節點，自身傘下（含自身）總算力 >= 100,000T，持倉數 >= 100 T，傘下累計業績算力日產出加速25%",
			t5: "<b>超級節點：</b><br/>至少3個直推，且每個直推或其傘下成員各有一個高級節點，自身傘下（含自身）總算力 >= 500,000T，持倉數 >= 100 T，傘下累計業績算力日產出加速35%",
			t6: "<b>創世節點：</b><br/>至少2個直推，且每個直推或其傘下成員各有一個超級節點，自身傘下（含自身）總算力 >= 1,500,000T，持倉數 >= 100 T，傘下累計業績算力日產出加速45%",
			t7: "<b>至尊節點：</b><br/>至少2個直推，且每個直推或其傘下成員各有一個創世節點，自身傘下（含自身）總算力 >= 3,000,000T，持倉數 >= 100 T，傘下累計業績算力日產出加速50%+全網加速5%（所有至尊節點均分全網5%）",
			t8: "持倉數=現有質押數+算力包總算力-累計提現",
			t9: "<i>要享受對於級別加速必須滿足對於條件：直推數量要求，下級等級要求，總算力要求，持倉數要求。未到達要求會導致加速失效。</i>"
		},
		income_info: {
			t0: "收益說明",
			t1: "您的收益大致分為三個部分：<b>靜態產出</b>、<b>網體加速產出</b>、<b>節點加速產出</b>",
			t2: "<b>靜態產出：</b>您每天將獲得種算力包5‰的收益<br/>",
			t3: "<b>網體加速產出：</b>您的下級每次產出獲取收益時，您都將獲得網體加速產出收益，將會按照比例自動同時產出收益，具體收益比例如下<br/><b>1層網絡：</b>12%加速，直推1人可享受加速<br/><b>2層網絡：</b>8%加速，直推3人可享受加速<br/><b>3層網絡：</b>6%加速，直推5人可享受加速<br/><b>4～10層網絡：</b>2%加速，直推7人可享受加速",
			t4: "<b>節點加速產出：</b>每當您傘下任一用戶產出收益時，都會向上尋找節點用戶，每種節點等級尋找一個，並按照節點等級對於的加速比例為節點帳戶加速產出<br/>",
			t5: "<b>什麼是幾層網絡？</b>從您開始的直推層級數。如您的直推成則為第1層，直推的直推為第2層，直推的直推的直推為第3層，以此類推",
			t6: "<b>提現手續費</b>您每次提現Pow收益將收取5%手續費，手續費將被直接打入黑洞進行銷毀",
		},
		release_info: {
			t0: "我的算力包",
			t1: "<b>待發放算力包</b>",
			t2: "編號",
			t3: "算力(T)",
			t4: "哈希",
			t5: "狀態",
		},
		income_details: {
			t0: "收益明細",
			t1: "<b>產出明細表</b>",
			t2: "收益類型",
			t3: "數量",
			t4: "時間",
		},
		swap_logs:{
			t0: "提現記錄",
			t1: "<b>提現明細表</b>",
			t2: "提現數量",
			t3: "提現時間",
			t4: "提現狀態",
		},
		invite_info: {
			t0: "邀請說明",
			t1: "邀請列表中僅展示了您的直推下級，再表格中您需要格外注意以下兩個數據",
			t2: "<b>傘下算力：</b>該直推下級自身和其所有下級的總算力",
			t3: "<b>等級：</b>您需要關注直屬下級的等級，對您的升級有很大幫助",
		},
		scrollView:[
			{ title: "Pow介紹", ref: "pow_view" },
			{ title: "邀請碼", ref: "share_view" },
			{ title: "我的資產", ref: "asset_view" },
			{ title: "我的收益", ref: "income_view" },
			{ title: "NFT質押", ref: "nft_view" },
			{ title: "邀請清單", ref: "invite_view" },
		],
	},
	"en": {
		head: {
			t1: "Connect Wallet",
			t2: "Wallet Address Copied Successfully",
			t3: "Disconnect",
			t4: "Do you want to disconnect the wallet?",
			t5: "Cancel",
			t6: "Confirm",
		},
		methods: {
			t1: "Wallet not detected, please install the wallet first",
			t2: "Failed to switch network, please refresh the page and try again",
			t3: "Wallet account not retrieved",
			t4: "Please refresh the page and re-sign authorization",
			t5: "Refresh Page",
			t6: "Close Dapp",
			t7: "Token added successfully",
			t8: "User rejected adding",
			t9: "Error adding token",
		},
		appview: {
			t1: "Decentralized AI Cloud Computing Platform",
			t2: "Connect Wallet",
			t3: "Signature failed",
		},
		homeview: {
			t1: "PowerLP is a new cloud computing power service launched by the DeFund Foundation, focusing on building a new financial ecosystem based on AI cloud computing power. We adhere to the core principles of the DeFund Foundation, aiming to deeply integrate blockchain technology, AI intelligence, and cloud computing power to become a leader in Web3 decentralized AI cloud computing. PowerLP provides users with an unprecedented investment experience and value growth opportunities. With its innovative technical architecture, efficient computing power services, and community-driven model, PowerLP is gradually becoming a shining new star in the decentralized finance field.",
			t2: "View All",
			t3: "Superior Code",
			t4: "My Code",
			t5: "Share and Promote",
			t6: "My assets",
			t7: "My Account",
			t8: "Pledge Amount",
			t9: "Total Exchanged",
			t9_1: "Number of holdings",
			t10: "Last Exchange",
			t10_1: "Pledge",
			t10_2: "Power",
			t11: "Exchange Power",
			t11_1: "Cancel",
			t12: "My Earnings",
			t13: "Withdrawable Amount",
			t14: "Released",
			t15: "Withdrawn",
			t15_1: "Withdraw all funds",
			t16: "Invitation List",
			t17: "User",
			t18: "Team Performance(T)",
			t19: "Level",
			t20: "No Data",
			t21: "Referral Relationship Binding",
			t22: "Referrer Invitation Code",
			t23: "Please Enter Invitation Code",
			t23_1: "The referrer cannot unbind after binding. Please check if the invitation code is correct",
			t24: "Cancel",
			t25: "Confirm",
			t26: "Recharge POW",
			t27: "Recharge Amount",
			t28: "Please Enter Recharge Amount",
			t29: "Available Balance",
			t30: "Exchange Computing Power Package",
			t31: "Exchange Amount",
			t32: "Minimum Exchange This Time:",
			t33: "Available for Exchange",
			t34: "Power Expansion",
			t35: "Inflation Factor",
			t36: "Please Enter Exchange Amount",
			t37: "One-Click Withdrawal",
			t38: "Withdrawal Address",
			t39: "Withdrawable Quantity",
			t39_1: "Minimum withdrawal",
			t39_2: "Exchange quantity error",
			t39_3: "The minimum exchange quantity is:",
			t40: "Fee",
			t41: "Revenue received",
			t42: "Withdraw",
			t43: "Please Enter Staking Amount",
			t44: "Staking Successful",
			t45: "Insufficient Balance",
			t46: "Copy Successful",
			t47: "Insufficient Available Balance, Please Recharge First",
			t48: "Exchange Successful",
			t49: "Please Enter Exchange Amount",
			t50: "Withdrawal Successful",
			t51: "Withdrawal Failed",
			t52: "Network error, please try again",
			t53: "Withdrawal Quantity Must Be Greater Than 0",
			t54: "ALL",
			t55: "Data on the chain is being executed, please refresh later",
			t56: "Total Power：",
		},
		logout: {
			t0: "Account Cancellation",
			t1: "Are you sure you want to cancel? Please read the following instructions carefully!",
			t2: "<b>Refunded Assets:</b> Refunded Assets = Total Investment (Staked Amount + Total Exchange) – Withdrawn Amount – Penalty Fee.",
			t3: "<b>Penalty Calculation:</b> 30% of the refunded asset amount will be deducted as a penalty fee.",
			t4: "<b>Refund Processing:</b> The redeemed assets will be automatically transferred to your wallet within 24 hours.",
			t5: "<i>The redemption request will be reviewed within 7 business days. During the review period, the power package will continue to release normally. Once approved, any unwithdrawn assets will be automatically withdrawn, the power package will be invalidated, and the network will be deregistered. Please proceed with caution!</i>",
			t6: "Cancel",
			t7: "Confirm",
			t8: "Operation successful, awaiting admin review",
			t9: "Operation failed, please try again",
			t10: "Estimated Refundable:",
			t11: "Estimated Penalty:",
			t12: "Got it",
			t13: "Cancel Cancellation Request",
			t14: "Cancellation Successful",
			t15: "Are you sure you want to cancel the cancellation request?",
			t16: "Cancellation request was rejected, view the reason",
			t17: "Cancellation request under review, view details",
			t18: "Your cancellation request was rejected, reason for rejection:",
			t19: "This pop-up message will only be displayed once and will not be shown again after reading.",
			t20: "The cancellation request is under review and is expected to be completed within 7 business days. During the review period, the power package will continue to release normally. Once approved, any unwithdrawn assets will be automatically withdrawn, the power package will be invalidated, and the network will be deregistered.",
			t21: "Read",
			t22: "Application Time:",
			t23: "Unable to cancel, the current estimated amount of POW that can be returned is less than or equal to 0",
			t24: "The total output has exceeded the total investment amount. There is no need to cancel the account. Please choose to withdraw the income directly",
		},
		nft: {
			t1: "NFT pledge dividends",
			t2: "Available for Staking",
			t3: "Staked",
			t4: "Stake",
			t5: "Unstake",
			t6: "Earnings this week",
			t7: "Withdraw Profit",
			t10: "Staked NFTs can earn cross-chain fee dividends",
			t11: "Claim dividends every Saturday at 6 AM",
			t12: "Weekly dividends can only be claimed once, and cannot be claimed after expiration"
		},
		nft_info: {
			t0: "NFT Staking Instructions",
			t1: "You can stake your NFTs into the contract to receive fee dividends.",
			t2: "<b>What is the fee dividend?</b> A corresponding fee is charged every time a user uses the cross-chain bridge to transfer Pow tokens across chains. By staking NFTs, you can share in all these fees.",
			t3: "<b>When can dividends be claimed?</b> Dividends are distributed every Saturday at 9 AM, and can be claimed once every 7 days.",
			t4: "<b>Note:</b> If dividends are not claimed within the week, they will expire and cannot be claimed. Please make sure to claim your dividend rewards within 7 days."
		},
		pledgeNft: {
			t0: "NFT Staking",
			t1: "Staking Quantity",
			t2: "Please enter staking quantity",
			t3: "All",
			t4: "Available Quantity",
			t5: "Cancel",
			t6: "Confirm Staking",
			t7: "Authorization Failed",
			t8: "Staking Successful",
			t9: "Staking Failed",
			t10: "Unstaking Successful",
			t11: "Unstaking Failed",
			t12: "Dividend Claim Successful",
			t13: "Dividend Claim Failed",
			t14: "Insufficient Unstaking Quantity",
			t15: "No Dividend Earnings",
			t16: "Insufficient NFT Quantity",
		},
		release_status: {
			t1: "releasing",
			t2: "Release completed",
		},
		release_type: {
			t1: "Static",
			t2: "Network Acceleration",
			t3: "Node Acceleration",
		},
		swap_status: {
			t1: "Pending",
			t2: "Rejected",
			t3: "Permission Granted for On-chain",
			t4: "On-chain",
		},
		level: {
			t1: "Normal",
			t2: "Beginner",
			t3: "Intermediate",
			t4: "Advanced",
			t5: "Super",
			t6: "Genesis",
			t7: "Supreme",
		},		  
		pow_info: {
			t0: "PowerLP Explanation",
			t1: "<b>What is PowerLP:</b><br />PowerLP is a new cloud computing power service launched by the DeFund Foundation, focusing on building a new financial ecosystem based on AI cloud computing power. We adhere to the core principles of the DeFund Foundation, aiming to deeply integrate blockchain technology, AI intelligence, and cloud computing power to become a leader in Web3 decentralized AI cloud computing. PowerLP provides users with an unprecedented investment experience and value growth opportunities. With its innovative technical architecture, efficient computing power services, and community-driven model, PowerLP is gradually becoming a shining new star in the decentralized finance field.",
			t2: "<b>POW Token:</b><br />Full Token Name: <b>AiPool Power Token</b> <br />Total Supply: <b>200 million</b> <br />Issuing Blockchain: <b>3TChat</b> <br />Issuing Price: <b>0.1 USDT</b> <br />Donation quantity: <b>10% (20 million)</b> <br />Mining Minting: <b>90% (180 million)</b>",
			t3: "Add POW to Wallet",
			t4: "<i>Click the button above to quickly add the POW token to your wallet.</i>",
		},
		deposit_info: {
			t0: "Redemption instructions",
			t1: "<b>Investment Rules:</b><br/>1. Only one computing power package can be held at a time;<br/>2. For reinvestment, it must be more than 10% of the previous investment and cannot be reduced;<br/>3. When the asset package is fully released, the network will retain for 7 days. If not reinvested, the LP computing power network will be cleared, and you can only participate again;<br/>4. Re-participation will generate a new network and establish a new computing power network;<br/>5. The minimum pledged quantity is 51.2T of computing power;",
			t2: "<b>Pledge rules:</b><br/>1. 10 POW tokens correspond to 1T of computing power;<br/>2. You need to first recharge POW tokens into the contract, then perform the exchange;",
			t3: "Exchange Amount",
			t4: "Inflation Factor",
		},
		level_info: {
			t0: "Node Level Description",
			t1: "<b>After investors join the network, they can start promoting it. Once the corresponding indicators are met, they will be awarded the corresponding level. Nodes are divided into six levels, and fulfilling the conditions will automatically upgrade them and provide the corresponding accelerated output.</b>",
			t2: "<b>Primary Node:</b><br/>At least 2 direct referrals, each with at least 3,000T computing power. The total computing power under their umbrella (including themselves) must be >= 10,000T, with a holding amount >= 100 T. The daily output of cumulative performance computing power under the umbrella is accelerated by 15%.",
			t3: "<b>Intermediate Node:</b><br/>At least 2 direct referrals, each with a member in their team or under their umbrella who is a Primary Node. The total computing power under their umbrella (including themselves) must be >= 50,000T, with a holding amount >= 100 T. The daily output of cumulative performance computing power under the umbrella is accelerated by 20%.",
			t4: "<b>Advanced Node:</b><br/>At least 2 direct referrals, each with a member in their team or under their umbrella who is an Intermediate Node. The total computing power under their umbrella (including themselves) must be >= 100,000T, with a holding amount >= 100 T. The daily output of cumulative performance computing power under the umbrella is accelerated by 25%.",
			t5: "<b>Super Node:</b><br/>At least 3 direct referrals, each with a member in their team or under their umbrella who is an Advanced Node. The total computing power under their umbrella (including themselves) must be >= 500,000T, with a holding amount >= 100 T. The daily output of cumulative performance computing power under the umbrella is accelerated by 35%.",
			t6: "<b>Genesis Node:</b><br/>At least 2 direct referrals, each with a member in their team or under their umbrella who is a Super Node. The total computing power under their umbrella (including themselves) must be >= 1,500,000T, with a holding amount >= 100 T. The daily output of cumulative performance computing power under the umbrella is accelerated by 45%.",
			t7: "<b>Supreme Node:</b><br/>At least 2 direct referrals, each with a member in their team or under their umbrella who is a Genesis Node. The total computing power under their umbrella (including themselves) must be >= 3,000,000T, with a holding amount >= 100 T. The daily output of cumulative performance computing power under the umbrella is accelerated by 50%, plus an additional 5% acceleration shared equally among all Supreme Nodes in the network.",
			t8: "Holding amount = Current staked amount + Total computing power of the computing power package - Cumulative withdrawals",
			t9: "<i>To enjoy level-based acceleration, you must meet the specific conditions: the number of direct referrals, the required lower-level nodes, the total computing power, and the holding amount. Failure to meet these requirements will result in loss of acceleration.</i>"
		},				  
		income_info: {
			t0: "Earnings Explanation",
			t1: "Your earnings are divided into three main parts: <b>Static Release</b>, <b>Network Acceleration Release</b>, and <b>Node Acceleration Release</b>",
			t2: "<b>Static Release:</b> You will earn 0.5% of the computing power package daily<br/>",
			t3: "<b>Network Acceleration Release:</b> Whenever your subordinates release earnings, you will receive network acceleration release earnings. The earnings will be released automatically according to the proportion, with specific ratios as follows:<br/><b>1st Layer Network:</b> 12% acceleration, direct referral of 1 person can enjoy acceleration<br/><b>2nd Layer Network:</b> 8% acceleration, direct referral of 3 people can enjoy acceleration<br/><b>3rd Layer Network:</b> 6% acceleration, direct referral of 5 people can enjoy acceleration<br/><b>4th to 10th Layer Network:</b> 2% acceleration, direct referral of 7 people can enjoy acceleration",
			t4: "<b>Node Acceleration Release:</b> Whenever any user under you releases earnings, nodes will be sought upwards, one for each node level, and the acceleration ratio corresponding to the node level will be applied to accelerate the release for the node account<br/>",
			t5: "<b>What is a few-layer network?</b> Refers to the level of direct referrals starting from you. For example, your direct referrals are at the 1st layer, direct referrals of your direct referrals are at the 2nd layer, and so on.",
			t6: "<b>Withdrawal fee</b> Each time you withdraw POW earnings, you will be charged a 5% handling fee, and the handling fee will be directly put into the black hole for destruction",
		},
		release_info: {
			t0: "My Computing Power Packages",
			t1: "<b>Pending Release Computing Power Packages</b>",
			t2: "Number",
			t3: "Power(T)",
			t4: "Hash",
			t5: "Status",
		},
		income_details: {
			t0: "Earnings Details",
			t1: "<b>Release Details Table</b>",
			t2: "Earnings Type",
			t3: "Amount",
			t4: "Time",
		},
		swap_logs: {
			t0: "Withdrawal Records",
			t1: "<b>Withdrawal Details Table</b>",
			t2: "Withdrawal Amount",
			t3: "Withdrawal Time",
			t4: "Withdrawal Status",
		},
		invite_info: {
			t0: "Invitation Explanation",
			t1: "The invitation list only displays your direct subordinates. In the table, you need to pay special attention to the following two data points:",
			t2: "<b>Umbrella Computing Power:</b> The total computing power of the direct subordinate and all their subordinates",
			t3: "<b>Level:</b> You need to pay attention to the level of direct subordinates, as it greatly helps with your upgrade",
		},
		scrollView: [
			{ title: "Pow Introduction", ref: "pow_view" },
			{ title: "Invitation Code", ref: "share_view" },
			{ title: "My Assets", ref: "asset_view" },
			{ title: "My Earnings", ref: "income_view" },
			{ title: "NFT Staking", ref: "nft_view" },
			{ title: "Invitation List", ref: "invite_view" },
		],
	},
};

const i18n = new VueI18n({
	locale: 'zh-hant', // 默认语言
	fallbackLocale: 'zh-hant', // 未找到翻译时的默认语言
	messages
});

export default i18n;
