<template>
	<div class="header">
		<img src="../assets/logo2.png" class="logo" @click="toHome()">
		<div class="wallet" @click="logout">
			<img src="../assets/wallet.png">
			<span>{{ userAccount | address }}</span>
		</div>
		<div class="language" @click="setLanguage">
			<img :src="language_img" style="width:20px;">
			<span>{{language=='zh-hant'?'繁體':'EN'}}</span>
		</div>

		<!-- 斷開連接 -->
		<van-dialog v-model="show_logout" overlay-class="mydialog" class="dialog1" :title="$t('head.t3')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<div>{{ $t('head.t4') }}</div>
				</div>
				<div class="btn_box">
					<div class="default_btn cancel" @click="show_logout = false">{{ $t('head.t5') }}</div>
					<van-button class="default_btn" @click="confirmLogout">{{ $t('head.t6') }}</van-button>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { connectWallet } from '../utils/eth_methods.js';
//加载img
import zh from '../assets/zh-hant.png';
import en from '../assets/en.png';
export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
        return {
			userAccount: '',		//用户账户
			language: 'zh-hant',	//语言
			language_img: zh,		//语言图片
			show_logout:false,
        };
    },
	async mounted() {
		//获取语言设置
		this.language = localStorage.getItem('language') || 'zh-hant';
		// 切换语言
		this.$i18n.locale = this.language
		this.language_img = this.language === 'zh-hant' ? zh : en;

		const account = localStorage.getItem("userAccount");
		if(account){
			this.userAccount = account;
		}else{
			const res = await this.connectWallet();
			this.$store.state.account = res;
			this.$emit('custom-event', res);
		}
		//链接钱包
		// const res = await this.connectWallet();
		// this.$store.state.account = res;
		// this.$emit('custom-event', res);
	},
	filters: {
		address(value) {
			if (!value) return '';
			return value.slice(0, 6) + '...' + value.slice(-4);
		},
	},
	methods: {
		connectWallet,	//链接钱包,获取用户账户
		toHome(){
			//如果是首页,点击logo刷新页面
			if(this.$route.path === '/'){
				window.location.reload();
			}else{
				this.$router.replace({ path: '/' });
			}
		},
		//切换语言
		setLanguage () {
			this.language = this.language === 'zh-hant' ? 'en' : 'zh-hant';
			// 切换语言
			this.$i18n.locale = this.language
			this.language_img = this.language === 'zh-hant' ? zh : en;
			// 保存语言设置
			localStorage.setItem('language', this.language)
		},
		//点击复制地址
		copy_address(){
			this.$copyText(this.userAccount).then(() => {
				this.$toast(this.$t('head.t2'));
			});
		},
		//断开链接
		logout(){
			this.show_logout = true;
		},
		confirmLogout(){
			this.$store.state.account = '';
			localStorage.clear();
			window.location.reload();
		},
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header{
	background: #151515;
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 0rem 1rem;
	height: 50px;
	align-items: center;

	position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
	max-width: calc(500px - 2rem);
	margin: auto;
	.logo{
		height:26px;
	}
	.wallet{
		display: flex;
		align-items: center;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		width: fit-content;
		border: solid 1px #999999;
		border-radius: 12px;
		padding: 0 13px;
		height: 24px;
		img{
			width: 16px;
		}
		span{
			margin:0 0.42rem;
			font-family: PingFang SC;
			font-size: 14px;
			font-weight: bold;
			color: #fff;
		}
	}
	.language{
		width: 66px;
		height: 24px;
		background: #FFFFFF;
		border: 1px solid #089553;
		border-radius: 1rem;
		display: flex;
		align-items: center;
		padding: 0 0.17rem;
		img{
			width: 20px;
			border-radius: 50%;
		}
		span{
			margin: 0 auto;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #089553;
			// line-height: 2.08rem;
		}
	}
}

.dialog1{
	height: 200px !important;
    min-height: auto !important;
}
.content_box{
	margin-top: 12px !important;
}
.btn_box {
	padding: 20px 0 30px !important;
	display: flex;
	gap: 10px;
}
</style>
