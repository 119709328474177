<template>
	<div class="container">
		<van-sticky :offset-top="50" class="my_sticky">
			<van-tabs v-model="activeTab" @click="scrollToElement">
				<van-tab v-for="(item, index) in $t('scrollView')" :title="item.title" :key="index" :name="item.ref"></van-tab>
			</van-tabs>
		</van-sticky>

		<img src="../assets/logo3.png" class="logo3" />
		<div class="box top" ref="pow_view" id="pow_view">
			<div class="pow_icon">
				<img src="../assets/pow_icon.png" class="logo2" />
			</div>

			<div class="illustrate">
				<span class="txt" @click="show_all = !show_all" :class="{ active: show_all }">{{ $t("homeview.t1") }}</span>
			</div>
			<!-- 查看全部 -->
			<div class="hollow_btn" @click="showPowInfo">{{ $t("homeview.t2") }}</div>
		</div>
		<!-- 推荐人 -->
		<div class="box invite" ref="share_view" id="share_view" v-if="parentInviteCode && myInviteCode">
			<div class="item item_line">
				<!-- 推荐人邀请码 -->
				<div class="label">{{ $t("homeview.t3") }}</div>
				<div class="value">{{ parentInviteCode }}</div>
			</div>
			<div class="item item_line">
				<!-- 我的邀请码 -->
				<div class="label">{{ $t("homeview.t4") }}</div>
				<div class="value" @click="copy(myInviteCode)">{{ myInviteCode }} <img class="copy_icon" src="../assets/copy.png" alt="" /></div>
			</div>
			<!-- 分享推广 -->
			<div class="default_btn share" @click="share">{{ $t("homeview.t5") }}</div>
		</div>
		<!-- 我的资产 -->
		<div class="box deposit" ref="asset_view" id="asset_view">
			<div class="title">
				<!-- 我的入金 -->
				<span>{{ $t("homeview.t6") }}</span>
				<img @click="showDepositInfo" src="../assets/info.png" class="info_icon" />
			</div>
			<div class="item">
				<div>
					<img src="../assets/headimg.png" class="headimg" />
				</div>
				<div class="userinfo">
					<div class="address">
						<!-- 我的帐号 -->
						<span>{{ $t("homeview.t7") }}</span>
						<img v-if="level > 0" :src="`/static/level${level}.png`" class="level" />
					</div>
					<div class="address_txt">
						<span>{{ account }}</span>
					</div>
				</div>
				<img @click="showLevelInfo" src="../assets/more.png" class="right_info_icon" />
			</div>
			<div class="townhouse" style="margin-top: 20px">
				<div>
					<!-- 質押數 -->
					<div class="label">{{ $t("homeview.t8") }}<span class="unit">Pow</span></div>
					<div class="value">{{ $utils.numToK(LpData.lpNumber - LpData.lpMintNumber, 4, 0) }}</div>
				</div>
				<div>
					<!-- 已兑换 -->
					<div class="label">{{ $t("homeview.t9") }}<span class="unit">Pow</span></div>
					<div class="value">{{ $utils.numToK(LpData.lpMintNumber, 4, 0) }}</div>
				</div>
			</div>
			<div v-if="LpData.topNumber > 0" class="townhouse" style="margin-top: 10px; position: relative">
				<div>
					<!-- 總持倉 -->
					<div class="label">{{ $t("homeview.t9_1") }}<span class="unit">T</span></div>
					<div class="value">{{ $utils.numToK(income.totalHold/10, 4, 0) }}</div>
				</div>
				<img @click="showRelease" src="../assets/more.png" class="right_info_icon" />
				<div style="height: 66px" v-if="released && released.length>0">
					<!-- 算力 -->
					<div class="label">{{ $t("homeview.t10_2") }}<span class="unit">T</span></div>
					<div class="value">{{ $utils.numToK(packagePower/10, 4, 0) }}</div>
				</div>
			</div>
			<div class="btn_box" style="margin-top: 20px" v-if="unregister_info && unregister_info.id && !unregister_info.hash">
				<!-- 撤銷申請被拒絕，查看原因 -->
				<div class="default_btn cancel" @click="showUnregisterInfo" v-if="unregister_info.status==2">{{ $t("logout.t16") }}</div>
				<!-- 撤銷申請審核中，查看詳情 -->
				<div class="default_btn cancel" @click="showUnregisterInfo" v-else>{{ $t("logout.t17") }}</div>
			</div>
			<div class="btn_box" style="margin-top: 20px" v-else>
				<!-- 質押 -->
				<van-button class="default_btn" :loading="rechargeLoading" :disabled="rechargeLoading" @click="recharge" :loading-text="$t('homeview.t10_1')">{{ $t("homeview.t10_1") }}</van-button>
				<!-- 兑换算力 -->
				<div class="default_btn cancel" @click="exchangeCancel" v-if="is_release===false">{{ $t("homeview.t11_1") }}</div>
				<div class="default_btn" @click="exchange" v-else>{{ $t("homeview.t11") }}</div>
			</div>
		</div>
		<!-- 收益 -->
		<div class="box deposit" ref="income_view" id="income_view">
			<div class="title">
				<!-- 我的收益 -->
				<span>{{ $t("homeview.t12") }}</span>
				<img src="../assets/info.png" class="info_icon" @click="showIncomeInfo" />
				<div v-if="config && config.totalpower != undefined" class="total_power">{{ $t("homeview.t56") }}<span>{{ $utils.numToK(config.totalpower + totalPower, 4, 0) }} T</span></div>
			</div>
			<div class="money">
				<!-- 可提現金額 -->
				<div>{{ $t("homeview.t13") }}</div>
				<div class="value" style="line-height: 26px;">{{ $utils.numToK(income.drawable, 4, 0) }} Pow</div>
			</div>
			<div class="townhouse" style="margin-top: 20px">
				<div>
					<!-- 總收益 -->
					<div class="label">{{ $t("homeview.t14") }}<span class="unit">Pow</span></div>
					<div class="value">{{ $utils.numToK(income.totalReward, 4, 0) }}</div>
					<img @click="showIncomeDetails" src="../assets/info2.png" class="right_info_icon" />
				</div>
				<div>
					<!-- 已提現 -->
					<div class="label">{{ $t("homeview.t15") }}<span class="unit">Pow</span></div>
					<div class="value">{{ $utils.numToK(income.swapAmount, 4, 0) }}</div>
					<img @click="showSwapLogs" src="../assets/info2.png" class="right_info_icon" />
				</div>
			</div>
			<van-button class="default_btn" style="margin-top: 36px" :loading="showWithdrawLoading" :disabled="showWithdrawLoading" @click="showWithdraw" :loading-text="$t('homeview.t15_1')">{{ $t("homeview.t15_1") }}</van-button>
		</div>
		<!-- NFT -->
		<div class="box nft" ref="nft_view" id="nft_view">
			<div class="title">
				<!-- 我的NFT -->
				<span>{{ $t("nft.t1") }}</span>
				<img src="../assets/info.png" class="info_icon" @click="show_nft_info = true" />
			</div>
			<div class="nft_img">
				<img src="../assets/nft.png" alt="" />
			</div>
			<div class="townhouse" style="margin-top: 20px">
				<div>
					<!-- 可质押 -->
					<div class="label">{{ $t("nft.t2") }}</div>
					<div class="value">{{ $utils.numToK(nft_info.nftBalance, 0, 0) }} NFT</div>
				</div>
				<div>
					<!-- 已质押 -->
					<div class="label">{{ $t("nft.t3") }}</div>
					<div class="value">{{ $utils.numToK(nft_info.pledgeBalance, 0, 0) }} NFT</div>
				</div>
			</div>
			<div class="btn_box" style="margin-top: 20px">
				<!-- 質押 -->
				<van-button class="default_btn" @click="showPledgeNFT" :loading="pledgeLoading" :disabled="pledgeLoading">{{ $t("nft.t4") }}</van-button>
				<!-- 撤回質押 -->
				<van-button class="default_btn" @click="withdrawPledgeNFT" :loading="withdrawPledgeNFTLoading" :disabled="withdrawPledgeNFTLoading">{{ $t("nft.t5") }}</van-button>
			</div>
			<div class="money">
				<!-- 本週收益分紅 -->
				<div>{{ $t("nft.t6") }}</div>
				<div class="value">{{ $utils.numToK(nft_info.income, 4, 0) }} Pow</div>
			</div>

			<van-button class="default_btn" style="margin-top: 20px" :loading="claimRewardLoading" :disabled="claimRewardLoading" @click="withdrawNFTIncome" :loading-text="$t('nft.t7')">{{ $t("nft.t7") }}</van-button>
		</div>
		<!-- 邀請列表 -->
		<div class="box deposit" ref="invite_view" id="invite_view">
			<div class="title">
				<!-- 邀請列表 -->
				<span>{{ $t("homeview.t16") }}</span>
				<img src="../assets/info.png" class="info_icon" @click="showInviteInfo" />
			</div>
			<div class="subtotal">
				<div class="th">
					<!-- 用戶 -->
					<div>
						<span>{{ $t("homeview.t17") }}</span>
					</div>
					<!-- 傘下算力 -->
					<div>
						<span>{{ $t("homeview.t18") }}</span>
					</div>
					<!-- 等級 -->
					<div>
						<span>{{ $t("homeview.t19") }}</span>
					</div>
				</div>
				<div class="tbody" v-if="invitedList.length > 0">
					<div class="tr" v-for="item in invitedList" :key="item.id">
						<div>{{ handleAddress(item.address) }}</div>
						<div>{{ item.contribute / 10 }}</div>
						<div>{{ handleLevel(item.level) }}</div>
					</div>
				</div>
				<div v-else class="none">
					<img src="../assets/no_record.png" alt="" />
					<!-- 暫無數據 -->
					<div>{{ $t("homeview.t20") }}</div>
				</div>
			</div>
		</div>
		<!-- 社群 -->
		<div class="icon_box">
			<div>
				<img src="../assets/telegram.png" alt="" @click="open('https://t.me/LP_Dao')">
			</div>
			<!-- <div>
				<img src="../assets/discord.png" alt="" @click="open('https://t.me/LP_Dao')">
			</div> -->
			<div>
				<img src="../assets/x.png" alt="" @click="open('https://x.com/defundofficial')">
			</div>
		</div>
		<div class="copyright">© 2024 DeFund. All Rights Reserved</div>
		<!-- 推薦關係綁定 -->
		<van-dialog v-model="show_bind_parent" :title="$t('homeview.t21')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<!-- 推薦人邀請碼 -->
					<div class="label_title">{{ $t("homeview.t22") }}</div>
					<div class="input_box">
						<!-- 請輸入邀請碼 -->
						<input type="text" :placeholder="$t('homeview.t23')" v-model="inviteCode" />
					</div>
					<div class="label_title">
						<span>{{ $t("homeview.t23_1") }}</span>
					</div>
				</div>
				<div class="btn_box">
					<!-- 取消 -->
					<div class="default_btn cancel" @click="show_bind_parent = false">{{ $t("homeview.t24") }}</div>
					<!-- 確認 -->
					<van-button class="default_btn" :loading="bindParentLoading" :disabled="bindParentLoading" @click="bindParent" :loading-text="$t('homeview.t25')">{{ $t("homeview.t25") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 質押Pow -->
		<van-dialog v-model="show_recharge" :title="$t('homeview.t26')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<!-- 質押數量 -->
					<div class="label_title">{{ $t("homeview.t27") }}</div>
					<div class="input_box">
						<!-- 請輸入質押數量 -->
						<van-field v-model="recharge_num" type="digit" :placeholder="$t('homeview.t28')">
							<template #button>
								<van-button class="default_btn" style="height: 32px !important; font-size: 14px" @click="recharge_num = recharge_balance">{{ $t("homeview.t54") }}</van-button>
							</template>
						</van-field>
					</div>
					<div class="label_title_tow">
						<!-- 可用餘額 -->
						<span>{{ $t("homeview.t29") }}</span>
						<span>{{ $utils.numToK(recharge_balance, 4, 2) }} Pow</span>
					</div>
				</div>
				<div class="btn_box">
					<div class="default_btn cancel" @click="show_recharge = false">{{ $t("homeview.t24") }}</div>
					<van-button class="default_btn" :loading="rechargeConfirmLoading" :disabled="rechargeConfirmLoading" @click="confirmRecharge" :loading-text="$t('homeview.t25')">{{ $t("homeview.t25") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 兑换算力包 -->
		<van-dialog v-model="show_exchange" :title="$t('homeview.t30')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<div class="label_title_tow" style="margin-top: 34px">
						<!-- 兌換金額 -->
						<span>{{ $t("homeview.t31") }}</span>
						<!-- 本次最低兌換： -->
						<span style="color:#999;">({{ $t("homeview.t32") + $utils.numToK(minSwapAmount, 4, 2) }})</span>
					</div>
					<div class="input_box">
						<van-field v-model="exchange_amount" type="digit" :placeholder="$t('homeview.t36')">
							<template #button>
								<van-button class="default_btn" style="height: 32px !important; font-size: 14px" @click="exchange_amount = can_lp">{{ $t("homeview.t54") }}</van-button>
							</template>
						</van-field>
					</div>
					<div class="label_title_right">
						<!-- 可兌換 -->
						<span>{{ $t("homeview.t33") }}</span>
						<span>{{ can_lp }} Pow</span>
					</div>

					<!-- 膨脹算力 -->
					<div class="label_title" style="margin-top: 10px">{{ $t("homeview.t34") }}</div>
					<div class="input_box readonly">{{ $utils.numToK((exchange_amount * exchange_rate) / 10, 4, 0) }}(T)</div>
					<div class="label_title_right">
						<!-- 膨脹係數 -->
						<span>{{ $t("homeview.t35") }}</span>
						<span>{{ exchange_rate }}</span>
					</div>
				</div>
				<div class="btn_box">
					<div class="default_btn cancel" @click="show_exchange = false">{{ $t("homeview.t24") }}</div>
					<van-button class="default_btn" :loading="exchangeConfirmLoading" :disabled="exchangeConfirmLoading" @click="confirmExchange" :loading-text="$t('homeview.t25')">{{ $t("homeview.t25") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 一鍵提現 -->
		<van-dialog v-model="show_withdraw_info" :title="$t('homeview.t37')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<div class="label_title_tow" style="margin-top: 10px">
						<!-- 提現地址 -->
						<span>{{ $t("homeview.t38") }}</span>
					</div>
					<div class="input_box readonly">
						<span style="font-size: 11px">{{ account }}</span>
					</div>
					<!-- 可提現數量 -->
					<div class="label_title" style="margin-top: 10px">{{ $t("homeview.t39") }}</div>
					<div class="input_box readonly">
						{{ $utils.numToK(can_withdraw_amount, 4, 2) }}
						<!-- <van-field v-model="can_withdraw_amount" type="number" placeholder="请输入兑换金额" /> -->
					</div>
					<div class="label_title_right" style="margin-top: 20px">
						<!-- 手續費 -->
						<span>{{ $t("homeview.t40") }}</span>
						<span>5%</span>
					</div>
					<div class="label_title_right">
						<!-- 最低提现 -->
						<span>{{ $t("homeview.t39_1") }} {{ minWidthdraw }} Pow</span>
					</div>
					<div class="label_title_right">
						<!-- 道帳收益 -->
						<span>{{ $t("homeview.t41") }}</span>
						<span>{{ $utils.numToK(can_withdraw_amount * 0.95, 4, 2) }} Pow</span>
					</div>
				</div>
				<div class="btn_box">
					<div class="default_btn cancel" @click="show_withdraw_info = false">{{ $t("homeview.t24") }}</div>
					<!-- 提現 -->
					<van-button class="default_btn" :loading="withdrawConfirmLoading" :disabled="withdrawConfirmLoading" @click="confirmWithdraw" :loading-text="$t('homeview.t42')">{{ $t("homeview.t42") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- POW說明 -->
		<van-dialog v-model="show_pow_info" :title="$t('pow_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<p v-html="$t('pow_info.t1')"></p>
				<p v-html="$t('pow_info.t2')"></p>
				<van-button @click="addTokenToWallet($store.state.config.Token)" :loading="addTokenLoading" :disabled="addTokenLoading" class="default_btn">
					<span>{{ $t("pow_info.t3") }}</span>
				</van-button>

				<p v-html="$t('pow_info.t4')"></p>
			</div>
		</van-dialog>
		<!-- 入金说明 -->
		<van-dialog v-model="show_deposit_info" :title="$t('deposit_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<p v-html="$t('deposit_info.t1')"></p>
				<p v-html="$t('deposit_info.t2')"></p>
				<div class="subtotal" style="margin-bottom: 40px">
					<div class="th">
						<div>
							<span>{{ $t("deposit_info.t3") }}</span>
						</div>
						<div>
							<span>{{ $t("deposit_info.t4") }}</span>
						</div>
					</div>
					<div class="tr" v-for="(item, index) in rate_arr" :key="index">
						<div>{{ item.amount }}</div>
						<div>{{ item.rate }}</div>
					</div>
				</div>
			</div>
		</van-dialog>
		<!-- 等級說明 -->
		<van-dialog v-model="show_level_info" :title="$t('level_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<p v-html="$t('level_info.t1')"></p>
				<p v-html="$t('level_info.t2')"></p>
				<p v-html="$t('level_info.t3')"></p>
				<p v-html="$t('level_info.t4')"></p>
				<p v-html="$t('level_info.t5')"></p>
				<p v-html="$t('level_info.t6')"></p>
				<p v-html="$t('level_info.t7')"></p>
				<p v-html="$t('level_info.t8')"></p>
				<p v-html="$t('level_info.t9')"></p>
			</div>
		</van-dialog>
		<!-- 收益說明 -->
		<van-dialog v-model="show_income_info" :title="$t('income_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<p v-html="$t('income_info.t1')"></p>
				<p v-html="$t('income_info.t2')"></p>
				<p v-html="$t('income_info.t3')"></p>
				<p v-html="$t('income_info.t4')"></p>
				<p v-html="$t('income_info.t5')"></p>
				<p v-html="$t('income_info.t6')"></p>
			</div>
		</van-dialog>
		<!-- 邀請說明 -->
		<van-dialog v-model="show_invite_info" :title="$t('invite_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<p v-html="$t('invite_info.t1')"></p>
				<p v-html="$t('invite_info.t2')"></p>
				<p v-html="$t('invite_info.t3')"></p>
			</div>
		</van-dialog>
		<!-- 我的算力包 -->
		<van-dialog v-model="show_release_info" :title="$t('release_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<!-- <p v-html="$t('release_info.t1')"></p> -->
				<div class="subtotal" style="margin-bottom: 40px">
					<div class="th">
						<div>
							<span>{{ $t("release_info.t2") }}</span>
						</div>
						<div>
							<span>{{ $t("release_info.t3") }}</span>
						</div>
						<div>
							<span>{{ $t("release_info.t4") }}</span>
						</div>
						<div>
							<span>{{ $t("release_info.t5") }}</span>
						</div>
					</div>
					<div class="tr" v-for="(item, index) in released" :key="index">
						<div>{{ item.assetId }}</div>
						<div>{{ $utils.numToK(item.balance / 10, 4, 0) }}</div>
						<div>{{ handleAddress(item.hash) }}</div>
						<div>{{ handleStatus(item.status) }}</div>
					</div>
				</div>
			</div>
		</van-dialog>
		<!-- 我的收益明细 -->
		<van-dialog v-model="show_income_details" :title="$t('income_details.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<!-- <p v-html="$t('income_details.t1')"></p> -->
				<div class="subtotal" style="margin-bottom: 40px">
					<div class="th">
						<div>
							<span>{{ $t("income_details.t2") }}</span>
						</div>
						<div>
							<span>{{ $t("income_details.t3") }}</span>
						</div>
						<div>
							<span>{{ $t("income_details.t4") }}</span>
						</div>
					</div>
					<div class="tr" v-for="(item, index) in release_log" :key="index">
						<div>{{ handleType(item.type) }}</div>
						<div>{{ $utils.numToK(item.amount, 4, 0) }}</div>
						<div>{{ handleTime(item.time) }}</div>
					</div>
				</div>
			</div>
		</van-dialog>
		<!-- 我的提现明细 -->
		<van-dialog v-model="show_swap_info" :title="$t('swap_logs.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<!-- <p v-html="$t('swap_logs.t1')"></p> -->
				<div class="subtotal" style="margin-bottom: 40px">
					<div class="th">
						<div>
							<span>{{ $t("swap_logs.t2") }}</span>
						</div>
						<div>
							<span>{{ $t("swap_logs.t3") }}</span>
						</div>
						<div>
							<span>{{ $t("swap_logs.t4") }}</span>
						</div>
					</div>
					<div class="tr" v-for="(item, index) in swap_log" :key="index">
						<div>{{ $utils.numToK(item.usdt, 4, 0) }}</div>
						<div>{{ handleTime(item.time) }}</div>
						<div>{{ handleSwapStatus(item.status) }}</div>
					</div>
				</div>
			</div>
		</van-dialog>
		<!-- NFT說明 -->
		<van-dialog v-model="show_nft_info" :title="$t('nft_info.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<p v-html="$t('nft_info.t1')"></p>
				<p v-html="$t('nft_info.t2')"></p>
				<p v-html="$t('nft_info.t3')"></p>
				<p v-html="$t('nft_info.t4')"></p>
			</div>
		</van-dialog>
		<!-- 質押NFT -->
		<van-dialog v-model="show_pledge_nft" :title="$t('pledgeNft.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<!-- 質押數量 -->
					<div class="label_title">{{ $t("pledgeNft.t1") }}</div>
					<div class="input_box">
						<!-- 請輸入質押數量 -->
						<van-field v-model="pledge_nft_num" type="digit" :placeholder="$t('pledgeNft.t2')">
							<template #button>
								<van-button class="default_btn" style="height: 32px !important; font-size: 14px" @click="pledge_nft_num = nft_info.nftBalance">{{ $t("pledgeNft.t3") }}</van-button>
							</template>
						</van-field>
					</div>
					<div class="label_title_tow">
						<!-- 可用餘額 -->
						<span>{{ $t("pledgeNft.t4") }}</span>
						<span>{{ $utils.numToK(nft_info.nftBalance, 0, 0) }} NFT</span>
					</div>
				</div>
				<div class="btn_box">
					<div class="default_btn cancel" @click="show_pledge_nft = false">{{ $t("pledgeNft.t5") }}</div>
					<van-button class="default_btn" :loading="nftPledgeConfirmLoading" :disabled="nftPledgeConfirmLoading" @click="confirmNftPledge" :loading-text="$t('pledgeNft.t6')">{{ $t("pledgeNft.t6") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 撤銷彈窗 -->
		<van-dialog v-model="show_Log_out" :title="$t('logout.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<p v-html="$t('logout.t1')"></p>
					<p v-html="$t('logout.t2')"></p>
					<p v-html="$t('logout.t3')"></p>
					<p v-html="$t('logout.t4')"></p>
					<p v-html="$t('logout.t5')"></p>
					<p>{{$t('logout.t10')}}<i>{{ $utils.numToK(return_amount*0.7, 4, 2) }} Pow</i></p>
					<p>{{$t('logout.t11')}}<i>{{ $utils.numToK(return_amount*0.3, 4, 2) }} Pow</i></p>
					<p v-if="income.totalReward>=LpData.lpNumber"><i style="color:#F44336;">{{ $t('logout.t24') }} Pow</i></p>
				</div>
				<div class="btn_box" v-if="income.totalReward<LpData.lpNumber">
					<div class="default_btn cancel" @click="show_Log_out = false">{{ $t('logout.t6') }}</div>
					<van-button class="default_btn" @click="confirmLogout" :loading="confirmLogoutLoading" :disabled="confirmLogoutLoading" :loading-text="$t('logout.t7')">{{ $t('logout.t7') }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 撤銷申請信息彈窗 -->
		<van-dialog v-model="show_unregister_info" :title="$t('logout.t0')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box" v-if="unregister_info">
				<div class="content_box">
					<!-- 申請時間： -->
					<p>{{ $t('logout.t22') }}<i>{{ handleTime(unregister_info.create_time) }}</i></p>
					<p>{{$t('logout.t10')}}<i>{{ $utils.numToK(return_amount*0.7, 4, 2) }} Pow</i></p>
					<p>{{$t('logout.t11')}}<i>{{ $utils.numToK(return_amount*0.3, 4, 2) }} Pow</i></p>
					<div v-if="unregister_info.status==2" style="border-top: 1px solid #333;">
						<!-- 您的撤銷申請被拒絕，拒絕理由： -->
						<p>{{ $t('logout.t18') }}<b>{{ unregister_info.comment }}</b></p>
						<!-- 該彈窗消息僅顯示一次，閱讀後將不再顯示 -->
						<i>{{ $t('logout.t19') }}</i>
					</div>
					<!-- 撤銷申請正在審核中，預計7個工作日內完成審查，審核期間算力包正常釋放，審核通過後未提現的將自動提現、算力包失效、网体被注销。 -->
					<p v-else>{{ $t('logout.t20') }}</p>
				</div>
				<div v-if="unregister_info.status==2">
					<div class="btn_box">
						<!-- 已閱 -->
						<van-button class="default_btn" @click="read_unregister_fail" :loading="readUnregisterLoading" :disabled="readUnregisterLoading" :loading-text="$t('logout.t21')">{{ $t('logout.t21') }}</van-button>
					</div>
				</div>
				<div class="btn_box" v-else>
					<div class="default_btn cancel" @click="show_unregister_info = false">{{ $t('logout.t12') }}</div>
					<van-button class="default_btn" @click="showCancelUnregister">{{ $t('logout.t13') }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 取消撤銷 -->
		<van-dialog v-model="show_cancel_unregister" overlay-class="mydialog" class="dialog1" :title="$t('logout.t13')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<!-- 是否確認取消撤銷申請？ -->
					<div>{{ $t('logout.t15') }}</div>
				</div>
				<div class="btn_box">
					<div class="default_btn cancel" @click="show_cancel_unregister = false">{{ $t('logout.t6') }}</div>
					<van-button class="default_btn" @click="confirmCancelUnregister" :loading="confirmCancelUnregisterLoading" :disabled="confirmCancelUnregisterLoading" :loading-text="$t('logout.t7')">{{ $t('logout.t7') }}</van-button>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { fromWei, addTokenToWallet, getBalance, addLpNumber, getLpData, convertToLpToken, getNftInfo, pledgeNft, withdrawNft, claimReward } from "../utils/eth_methods.js";
import { Notify } from "vant-green";
export default {
	data() {
		return {
			parentInviteCode: "", //父级邀请码
			myInviteCode: "", //我的邀请码
			level: 1,
			show_all: false,

			show_pow_info: false, //POW說明
			show_deposit_info: false, //入金說明
			show_level_info: false, //等級說明
			show_income_info: false, //收益說明
			show_release_info: false, //我的算力包
			show_release_loading: false, //我的算力包loading
			addTokenLoading: false, //添加錢包按鈕loading
			show_income_details: false, //我的收益明细
			show_swap_info: false, //我的提現明细
			show_invite_info: false, //邀請列表
			show_nft_info: false, //NFT說明
			show_bind_parent: false,
			bindParentLoading: false,
			inviteCode: "",

			show_recharge: false,
			rechargeLoading: false,
			recharge_num: "", //質押数量
			recharge_balance: 0, //可用余额
			config: null, //链信息
			rechargeConfirmLoading: false,
			LpData: {
				lpNumber: 0,
				lpMintNumber: 0,
				topNumber: 0,
			},
			can_lp: 0,
			income: {
				totalHold: 0,
				totalReward: 0,
				blockReward: 0,
				levelReward: 0,
				nodeReward: 0,
				balance: 0,
				swapAmount: 0,
				withdrawAmount: 0,
				swapable: 0,
				drawable: 0,
			},
			released: [], //待释放收益
			is_release:true,	//是否允许兑换
			release_log: [], //释放记录
			packagePower:0,	//算力包总算力
			swap_log: [], //提現记录
			show_exchange: false, //兑换
			exchange_amount: "", //兑换金额
			exchange_rate: 1.8, //兑换比例
			exchangeConfirmLoading: false, //兑换确认按钮loading

			invitedList: [],
			rate_arr: [],
			minSwapAmount: 512, //最小兑换数量
			minWidthdraw: 100,
			show_withdraw_info: false, //提现弹窗
			showWithdrawLoading: false, //提现弹窗loading
			withdrawConfirmLoading: false, //提现确认按钮loading
			account: "", //钱包地址
			can_withdraw_amount: 0, //提现数量
			totalPower: 0, //总算力
			nft_info: {
				nftBalance: 0,
				pledgeBalance: 0,
				income: 0,
			}, //NFT信息
			pledgeLoading: false, //质押按钮loading
			show_pledge_nft: false, //质押NFT弹窗
			pledge_nft_num: "", //质押数量
			nftPledgeConfirmLoading: false, //质押确认按钮loading
			withdrawPledgeNFTLoading: false, //撤回质押NFT按钮loading
			claimRewardLoading: false, //领取收益按钮loading

			activeTab: "pow_view", // 默认选中的标签
			scrollView: [],
			timer: null,

			show_Log_out: false,	//撤銷彈窗
			confirmLogoutLoading: false,	//撤銷彈窗loading
			return_amount:0,		//可贖回数量
			unregister_info: null,	//撤銷信息
			show_unregister_info: false,	//撤銷詳情
			confirmCancelUnregisterLoading: false,	//取消撤銷確認按鈕loading
			show_cancel_unregister: false,	//取消撤銷彈窗
			readUnregisterLoading: false,	//已閱讀
		};
	},
	async mounted() {
		this.scrollView = this.$t("scrollView");
		// this.setupIntersectionObserver();
		window.addEventListener("scroll", this.updateActiveTab);

		//获取url中inviteCode
		this.account = this.$store.state.account;
		this.inviteCode = this.$route.query.inviteCode;
		this.config = this.$store.state.config;
		this.nft_info = this.$store.state.nftInfo;
		this.totalPower = this.$store.state.totalPower;

		for (let i = 0; i < this.config.level.length; i++) {
			let amount = `${this.config.level[i][1]/10} <= T`;
			if(i+1 < this.config.level.length){
				amount += ` < ${this.config.level[i+1][1]/10}`
			}
			this.rate_arr.push({
				rate: this.config.level[i][0],
				pow: this.config.level[i][1],
				amount: amount,
			})
		}
		
		if (this.$store.state.parentInviteCode) {
			this.parentInviteCode = this.$store.state.parentInviteCode;
			this.myInviteCode = this.$store.state.myInviteCode;
			this.level = this.$store.state.level;
			this.invitedList = this.$store.state.invitedList?this.$store.state.invitedList:[];
			this.LpData = this.$store.state.LpData;
			this.can_lp = parseInt(this.LpData.lpNumber - this.LpData.lpMintNumber);
			this.income = this.$store.state.income;
			this.unregister_info = this.$store.state.unregister_info;
			this.released = this.$store.state.released?this.$store.state.released:[];
			for(let i=this.released.length-1;i>=0;i--){
				if(this.released[i].status===1){
					this.is_release = false;
					this.packagePower = this.released[i].balance;
					break;
				}
			}
		} else {
			this.show_bind_parent = true;
		}
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.updateActiveTab);
	},
	watch: {
		exchange_amount(newValue) {
			if (newValue > this.can_lp) {
				this.exchange_amount = this.can_lp;
			}
			let rate = this.rate_arr[0].rate;
			for (let i = 0; i < this.rate_arr.length; i++) {
				if (newValue >= this.rate_arr[i].pow) {
					rate = this.rate_arr[i].rate;
				} else {
					break;
				}
			}
			this.exchange_rate = rate;
		},
	},
	methods: {
		fromWei,
		addTokenToWallet,
		getBalance,
		addLpNumber,
		getLpData,
		getNftInfo,
		pledgeNft,
		withdrawNft,
		claimReward,
		// 滚动到指定元素
		scrollToElement(ele) {
			this.timer && clearTimeout(this.timer);
			window.removeEventListener("scroll", this.updateActiveTab);
			const element = this.$refs[ele];
			const elementTop = element.getBoundingClientRect().top + window.scrollY;
			// 滚动到目标位置，并距离顶部50px
			window.scrollTo({
				top: elementTop - 150, // 距离顶部50px
				behavior: "smooth", // 平滑滚动
			});
			this.timer = setTimeout(() => {
				window.addEventListener("scroll", this.updateActiveTab);
			},1000)
		},
		setupIntersectionObserver() {
			const observerOptions = {
				root: null,
				rootMargin: "150px 0px 0px 0px", // 模拟固定顶部150px的检测
				threshold: 1, // 元素的50%可见时触发
			};

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.activeTab = entry.target.getAttribute("id");
					}
				});
			}, observerOptions);

			this.scrollView.forEach((item) => {
				const element = document.getElementById(item.ref);
				if (element) {
					observer.observe(element);
				}
			});
		},
		updateActiveTab() {
			let activeTab = "";
			for (const item of this.scrollView) {
				const element = document.getElementById(item.ref);
				if (element) {
					const rect = element.getBoundingClientRect();
					const elementTop = rect.top + window.scrollY;
					// 当元素顶部距离视口顶部的距离小于150px时，更新activeTab
					if (elementTop <= window.scrollY + 150 && elementTop + element.offsetHeight > window.scrollY) {
						activeTab = item.ref;
					}
				}
			}
			this.activeTab = activeTab;
		},

		async changeAccount(account) {
			this.userAccount = account;
		},
		showPowInfo() {
			this.show_pow_info = true;
		},
		showDepositInfo() {
			this.show_deposit_info = true;
		},
		showLevelInfo() {
			this.show_level_info = true;
		},
		showIncomeInfo() {
			this.show_income_info = true;
		},
		showInviteInfo() {
			this.show_invite_info = true;
		},
		async showRelease() {
			this.$toast.loading({
				message: "Loading...",
			});
			await this.queryRelease();
			this.$toast.clear();
			this.show_release_info = true;
		},
		async showIncomeDetails() {
			this.$toast.loading({
				message: "Loading...",
			});
			await this.incomeDetails();
			this.$toast.clear();
			this.show_income_details = true;
		},
		async showSwapLogs() {
			this.$toast.loading({
				message: "Loading...",
			});
			await this.swapLogs();
			this.$toast.clear();
			this.show_swap_info = true;
		},
		handleAddress(address) {
			return address.slice(0, 6) + "..." + address.slice(-4);
		},
		handleStatus(status) {
			// 1:待釋放 2:已釋放
			return this.$t("release_status.t" + status);
		},
		handleType(type) {
			// 1:靜態釋放 2:網體加速釋放 3:節點加速釋放
			return this.$t("release_type.t" + type);
		},
		handleSwapStatus(status) {
			// 1:申請中 2:被拒絕 3:許可上鏈 4:已上鏈
			return this.$t("swap_status.t" + status);
		},
		handleLevel(level) {
			// 1:初级 2:中级 3:高级 4:超级 5:创世 6:至尊
			return this.$t("level.t" + level);
		},
		handleTime(time) {
			const date = new Date(time * 1000);
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const hour = date.getHours();
			const minute = date.getMinutes();
			const second = date.getSeconds();
			const mm = month < 10 ? "0" + month : month;
			const dd = day < 10 ? "0" + day : day;
			const hh = hour < 10 ? "0" + hour : hour;
			const ii = minute < 10 ? "0" + minute : minute;
			const ss = second < 10 ? "0" + second : second;
			return `${year}-${mm}-${dd} ${hh}:${ii}:${ss}`;
		},

		//获取资产
		async assetData() {
			this.LpData = await this.getLpData(this.config.assetPackage);
			this.can_lp = parseInt(this.LpData.lpNumber - this.LpData.lpMintNumber);
			console.log("LpData:",this.LpData);
		},
		//質押
		async recharge() {
			this.rechargeLoading = true;
			if (!this.parentInviteCode) {
				await this.pioneer();
			}
			if (this.parentInviteCode) {
				// this.rechargeLoading = true;
				this.recharge_balance = await this.getBalance(this.config.Token);
				// this.rechargeLoading = false;
				this.show_recharge = true;
			} else {
				this.show_bind_parent = true;
			}
			this.rechargeLoading = false;
		},
		//确认質押
		async confirmRecharge() {
			const recharge_num = Number(this.recharge_num);
			const recharge_balance = Number(this.recharge_balance);
			if (recharge_num <= 0) {
				Notify({
					type: "danger",
					message: this.$t("homeview.t43"), //請輸入質押數量
				});
				return;
			}
			if (recharge_num <= recharge_balance) {
				this.rechargeConfirmLoading = true;
				await this.addLpNumber(this.recharge_num, this.config)
					.then((res) => {
						console.log(res);
						if (res && res.status) {
							this.show_recharge = false;
							this.recharge_num = 0;
							this.recharge_balance = 0;
							Notify({
								type: "success",
								message: this.$t("homeview.t44"), //質押成功
							});

							//查询質押数据
							this.assetData();
						}else{
							Notify({
								type: "danger",
								message: this.$t("homeview.t52"), 
							});
						}
					})
					.catch((err) => {
						console.log(err);
						Notify({
							type: "danger",
							message: this.$t("homeview.t52"), 
						});
					})
					.finally(() => {
						this.rechargeConfirmLoading = false;
					});
			} else {
				Notify({
					type: "danger",
					message: this.$t("homeview.t45"), //餘額不足
				});
			}
		},
		//绑定邀请码 /cst/request_code
		async bindParent() {
			this.bindParentLoading = true;
			try {
				const result = await this.$api.request(
					"/api/cst/request_code",
					{
						address: this.$store.state.account,
						code: this.inviteCode,
					},
					"POST"
				);
				if (result.code == 200) {
					this.parentInviteCode = this.inviteCode;
					this.$store.state.parentInviteCode = this.inviteCode;
					setTimeout(async () => {
						//查询我的邀请码
						await this.pioneer();
					}, 8000)
					Notify({
						type: "success",
						message: this.$t("homeview.t55"), //绑定成功,稍後刷新
					});
					this.show_bind_parent = false;
				} else if (result.code == 604) {
					Notify({
						type: "danger",
						message: result.msg,
						duration: 2000,
					});
					setTimeout(async () => {
						//查询我的邀请码
						await this.pioneer();
					}, 8000)
					this.show_bind_parent = false;
				} else {
					Notify({
						type: "danger",
						message: result.msg,
						duration: 2000,
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.bindParentLoading = false;
			}
		},
		//查询我的信息（我的邀請碼 父級邀請碼 邀請列表。。）
		async pioneer() {
			try {
				const result = await this.$api.request("/api/cst/pioneer");
				if (result.code == 200) {
					this.$store.state.parentInviteCode = result.data.fatherAddress;
					this.$store.state.myInviteCode = result.data.code;
					this.$store.state.level = result.data.level;
					this.$store.state.invitedList = result.data.invitedList ? result.data.invitedList : [];
					this.$store.state.invitedCount = result.data.invitedCount;

					this.parentInviteCode = result.data.fatherAddress;
					this.myInviteCode = result.data.code;
					this.level = result.data.level;
					this.invitedList = result.data.invitedList ? result.data.invitedList : [];
					this.invitedCount = result.data.invitedCount;
				}
			} catch (error) {
				console.log(error);
			}
		},
		//分享連結
		share() {
			//复制邀请链接 http://ip:port?inviteCode=${this.myInviteCode}
			const url = `${window.location.origin}/#/?inviteCode=${this.myInviteCode}`;
			this.copy(url);
		},
		copy(txt) {
			this.$copyText(txt).then(() => {
				Notify({
					type: "success",
					message: this.$t("homeview.t46"), //複製成功
				});
			});
		},
		//兑换算力
		async exchange() {
			if (this.LpData.lpNumber > this.LpData.lpMintNumber) {
				this.minSwapAmount = this.LpData.topNumber > 0 ? this.LpData.topNumber * 1.1 : this.minSwapAmount;
				this.show_exchange = true;
			} else {
				Notify({
					type: "danger",
					message: this.$t("homeview.t47"), //可用餘額不足，請先質押
				});
			}
		},
		
		//确认兑换
		async confirmExchange() {
			if (this.exchange_amount <= 0) {
				Notify({
					type: "danger",
					message: this.$t("homeview.t39_2"), //數量不足
				});
				return;
			}
			if (this.exchange_amount < this.minSwapAmount) {
				Notify({
					type: "danger",
					message: this.$t("homeview.t39_3") + this.minSwapAmount, //最小數量為：
				});
				return;
			}
			if (this.exchange_amount > this.LpData.lpNumber) {
				Notify({
					type: "danger",
					message: this.$t("homeview.t45"), //數量不足
				});
				return;
			}
			this.exchangeConfirmLoading = true;
			try {
				const exchange_amount = parseInt(Number(this.exchange_amount));
				if (exchange_amount > 0) {
					const result = await this.$api.request(
						"/csttoken/dapp/swapAsset",
						{
							address: this.$store.state.account,
							amount: exchange_amount,
							code: this.parentInviteCode,
						},
						"POST"
					);
					if (result.code == 200) {
						Notify({
							type: "success",
							message: this.$t("homeview.t48"), //兑换成功
						});
						this.show_exchange = false;
						this.exchange_amount = 0;
						this.assetData();
						this.queryIncome();
						this.queryRelease();
					}else{
						Notify({
							type: "danger",
							message: result.message
						});
					}
				} else {
					Notify({
						type: "danger",
						message: this.$t("homeview.t49"), //请输入兑换金额
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("homeview.t52"), //Network error
				});
			} finally {
				this.exchangeConfirmLoading = false;
			}
		},
		//查询收益
		async queryIncome() {
			try {
				const result = await this.$api.request("/api/cst/asset", {}, "GET");
				if (result.code == 200) {
					this.income = result.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		//查询待释放收益
		async queryRelease() {
			try {
				const result = await this.$api.request("/api/cst/to_be_released", {}, "GET");
				if (result.code == 200) {
					this.released = result.data?result.data:[];
					for(let i=this.released.length-1;i>=0;i--){
						if(this.released[i].status===1){
							this.is_release = false;
							this.packagePower = this.released[i].balance;
							break;
						}
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		//查询收益明细
		async incomeDetails() {
			try {
				const result = await this.$api.request("/api/cst/release_log", {}, "GET");
				if (result.code == 200) {
					this.release_log = result.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		//查詢體現明細
		async swapLogs() {
			try {
				const result = await this.$api.request("/api/cst/swap_log", {}, "GET");
				if (result.code == 200) {
					this.swap_log = result.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		//提现
		async showWithdraw() {
			this.showWithdrawLoading = true;
			await this.queryIncome();
			this.account = this.$store.state.account;
			this.can_withdraw_amount = this.income.swapable;
			this.showWithdrawLoading = false;

			this.show_withdraw_info = true;
		},
		//确认提现
		async confirmWithdraw() {
			if (this.can_withdraw_amount <= 0) {
				Notify({
					type: "danger",
					message: this.$t("homeview.t53"), //提现失败
				});
				return;
			}
			if (this.can_withdraw_amount < this.minWidthdraw) {
				Notify({
					type: "danger",
					message: this.$t("homeview.t39_1") + this.minWidthdraw + " Pow", //提现失败
				});
				return;
			}
			this.withdrawConfirmLoading = true;
			try {
				const result = await convertToLpToken(this.account, this.can_withdraw_amount, this.config.assetPackage);
				await this.queryIncome();
				if (result && result.status) {
					this.show_withdraw_info = false;
					Notify({
						type: "success",
						message: this.$t("homeview.t50"), //提现成功
					});
				} else {
					Notify({
						type: "danger",
						message: this.$t("homeview.t51"), //提现失败
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("homeview.t52"), //Network error
				});
			} finally {
				this.withdrawConfirmLoading = false;
			}
		},
		//查詢NFT信息
		async getNftData() {
			const nftInfo = await this.getNftInfo();
			console.log("NFT:",nftInfo);
			this.$store.state.nftInfo = nftInfo;
			this.nft_info = nftInfo;
		},
		//質押nft
		async showPledgeNFT() {
			this.pledgeLoading = true;
			await this.getNftData();
			this.pledgeLoading = false;
			this.show_pledge_nft = true;
		},
		//確認質押nft
		async confirmNftPledge() {
			if (this.nft_info.nftBalance <= 0 || this.pledge_nft_num <= 0 || this.pledge_nft_num > this.nft_info.nftBalance) {
				Notify({
					type: "danger",
					message: this.$t("pledgeNft.t16"), //NFT數量不足
				});
				return;
			}

			try {
				this.nftPledgeConfirmLoading = true;
				const result = await this.pledgeNft(this.pledge_nft_num);
				if (result) {
					await this.getNftData();
					this.show_pledge_nft = false;
					this.pledge_nft_num = "";
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("homeview.t52"), //Network error
				});
			} finally {
				this.nftPledgeConfirmLoading = false;
			}
		},
		//撤回質押NFT
		async withdrawPledgeNFT() {
			if (this.nft_info.pledgeBalance <= 0) {
				Notify({
					type: "danger",
					message: this.$t("pledgeNft.t14"), //撤回數量不足
				});
				return;
			}
			try {
				this.withdrawPledgeNFTLoading = true;
				await this.withdrawNft(this.nft_info.pledgeBalance);
				await this.getNftData();
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("homeview.t52"), //Network error
				});
			} finally {
				this.withdrawPledgeNFTLoading = false;
			}
		},
		//提取NFT收益
		async withdrawNFTIncome() {
			if (this.nft_info.income <= 0) {
				Notify({
					type: "danger",
					message: this.$t("pledgeNft.t15"), //暫無收益
				});
				return;
			}
			try {
				this.claimRewardLoading = true;
				await this.claimReward();
				await this.getNftData();
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("homeview.t52"), //Network error
				});
			} finally {
				this.claimRewardLoading = false;
			}
		},
		//打开页面
		open(url){
			window.open(url)
		},
		//查詢撤銷狀態
		async queryUnregister(){
			try {
				const result = await this.$api.request("/api/cst/get_unregister_info", {}, "GET");
				if (result.code == 200) {
					this.unregister_info = result.data;
				}
			} catch (error) {
				console.log(error);
			}	
		},
		//撤銷
		exchangeCancel(){
			//計算可贖回金額和違約金
			this.return_amount = this.LpData.lpNumber - this.income.swapAmount;
			this.show_Log_out = true;
		},
		//確認撤銷
		async confirmLogout(){
			if(this.return_amount <= 0){
				Notify({
					type: "danger",
					message: this.$t("logout.t23"),
				});
				return
			}
			//总产出>=总投资，无意义撤回
			if(this.income.totalReward >= this.LpData.lpNumber){
				Notify({
					type: "danger",
					message: this.$t("logout.t24"),
				});
				return
			}
			this.confirmLogoutLoading = true;
			try {
				const result = await this.$api.request("/api/cst/unregister",{
					reAmount: parseFloat(this.return_amount*0.7),
					deAmount: parseFloat(this.return_amount*0.3)
				},"POST");
				if (result.code == 200) {
					Notify({
						type: "success",
						message: this.$t("logout.t8"), //兑换成功
					});
					await this.queryUnregister();
					this.show_Log_out = false;
					this.queryIncome();
					this.queryRelease();
					this.getLpData();
				}else{
					Notify({
						type: "danger",
						message: result.msg, //请输入兑换金额
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("logout.t9"), //Network error
				});
			} finally {
				this.confirmLogoutLoading = false;
			}
		},
		//顯示撤回詳情
		showUnregisterInfo(){
			//計算可贖回金額和違約金
			this.return_amount = this.LpData.lpNumber - this.income.swapAmount;
			this.show_unregister_info = true;
		},
		//取消撤銷確認彈窗
		showCancelUnregister(){
			this.show_unregister_info = false;
			this.show_cancel_unregister = true;	
		},
		//取消撤銷
		async confirmCancelUnregister(){
			this.confirmCancelUnregisterLoading = true;
			try {
				const result = await this.$api.request("/api/cst/revoke",{},"POST");
				if (result.code == 200) {
					Notify({
						type: "success",
						message: this.$t("logout.t14"), //取消撤銷成功
					});
					await this.queryUnregister();
					this.show_cancel_unregister = false;
					this.queryIncome();
					this.queryRelease();
					this.getLpData();
				}else{
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("logout.t9"), //Network error
				});
			} finally {
				this.confirmCancelUnregisterLoading = false;
			}
		},
		//已閱讀
		async read_unregister_fail(){
			this.readUnregisterLoading = true;
			try {
				const result = await this.$api.request("/api/cst/read", {
					idlist: [this.unregister_info.id]
				}, "POST");
				if (result.code == 200) {
					await this.queryUnregister();
					this.show_unregister_info = false;
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.readUnregisterLoading = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.container {
	padding: 95px 15px 0;
	padding-bottom: 100px;
	background-size: 100%;
	background-position-y: 50px;
	min-height: calc(100vh - 150px);
	// background: url('../assets/logo3.png') no-repeat #151515;
	// background-size: 100%;
}

.logo3 {
	width: 100%;
}
.top {
	line-height: 1;
	margin-bottom: 15px;
	.title {
		margin-top: 40px;
		font-family: D-DINExp-Bold;
		font-size: 40px;
		font-weight: normal;
		font-stretch: normal;
		text-align: center;
		span {
			background: linear-gradient(90deg, #88ebfa 0%, #f2b3ec 48%, #ffd24f 100%);
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
		}
	}
	.pow_icon {
		text-align: center;
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #ffffff;
		.logo2 {
			height: 22px;
			margin: auto;
		}
	}
	.illustrate {
		margin-top: 30px;
		transition: 300ms;
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #ffffff;
		.txt {
			display: -webkit-box;
			line-clamp: 4;
			-webkit-line-clamp: 4; /* 显示的行数 */
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.txt.active {
			line-clamp: 100;
			-webkit-line-clamp: 100;
		}
	}
	.hollow_btn {
		margin-top: 30px;
	}
}
.box {
	padding: 20px 15px;
	background-color: #262626;
	border-radius: 10px;
	margin-top: 10px;

	.title {
		display: flex;
		gap: 10px;
		align-items: center;
		font-family: PingFang-SC-Bold;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 25px;
		letter-spacing: 0px;
		color: #ffffff;
		.info_icon {
			width: 18px;
			height: 18px;
		}
	}
}
.copy_icon {
	width: 16px;
	position: absolute;
	right: 8px;
}
.invite {
	.item {
		margin-bottom: 10px;
		.label {
			font-family: PingFang-SC-Bold;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 25px;
			letter-spacing: 0px;
			color: #ffffff;
		}
		.value {
			margin-top: 10px;
			background-color: #151515;
			border-radius: 12px;
			height: 44px;
			font-family: PingFang-SC-Medium;
			font-weight: normal;
			font-stretch: normal;
			line-height: 18px;
			color: #999999;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			letter-spacing: 2px;
			font-size: 14px;
		}
	}
	.item_line {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 0 40px;
		position: relative;
		.value {
			max-width: calc(100% - 100px);
			width: 62%;
			margin: 0;
			height: 36px;
		}
	}
	.share {
		width: 80%;
		margin: 25px auto 0;
	}
}
.deposit {
	.item {
		margin-top: 15px;
		padding: 10px;
		background-color: #151515;
		border-radius: 12px;
		display: flex;
		align-items: center;
		gap: 10px;
		position: relative;
	}
	.headimg {
		width: 43px;
		height: 43px;
	}
	.level {
		width: 46px;
		height: 16px;
	}
	.userinfo {
		font-family: PingFang-SC-Bold;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #ffffff;
		overflow: hidden;
		.address {
			display: flex;
			align-items: center;
			gap: 7px;
		}
		.address_txt {
			font-family: PingFang-SC-Medium;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 18px;
			letter-spacing: 0px;
			color: #999999;
			margin-top: 4px;
			overflow: hidden;
			text-overflow: ellipsis;
			// /* white-space: nowrap;
		}
	}

	.townhouse {
		display: flex;
		gap: 10px;

		> div {
			flex: 1;
			height: 66px;
			background-color: #151515;
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			position: relative;
			> div.label {
				font-family: PingFang-SC-Medium;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 18px;
				letter-spacing: 0px;
				color: #999999;
			}
			> div.value {
				font-family: PingFang-SC-Bold;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 18px;
				letter-spacing: 0px;
				color: #ffffff;
			}
		}
	}

	.money_box {
		margin-top: 16px;
		position: relative;
		height: 84px;
		padding: 10px;
	}

	.money {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		font-family: PingFang-SC-Medium;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #ffa511;
		padding: 20px 0;
		.value {
			margin-top: 15px;
			font-family: PingFang-SC-Bold;
			font-size: 22px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 18px;
			letter-spacing: 0px;
			background: linear-gradient(45deg, #88ebfa, #f2b3ec, #ffd24f);
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
		}
	}

	.topNumber {
		background: #151515;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
}

.nft {
	.townhouse {
		display: flex;
		gap: 10px;

		> div {
			flex: 1;
			height: 66px;
			background-color: #151515;
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			position: relative;
			> div.label {
				font-family: PingFang-SC-Medium;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 18px;
				letter-spacing: 0px;
				color: #999999;
			}
			> div.value {
				font-family: PingFang-SC-Bold;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 18px;
				letter-spacing: 0px;
				color: #ffffff;
			}
		}
	}
	.nft_img {
		width: 180px;
		height: 180px;
		background: #151515;
		border-radius: 12px;
		position: relative;
		margin: 30px auto 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: inset 0px -12px 36px rgba(0, 0, 0, 0.4), 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
		img {
			width: 100%;
		}
	}
	.money {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		font-family: PingFang-SC-Medium;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #ffa511;
		padding: 20px 0;

		background: #151515;
		margin-top: 44px;
		border-radius: 12px;
		.value {
			margin-top: 15px;
			font-family: PingFang-SC-Bold;
			font-size: 22px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 18px;
			letter-spacing: 0px;
			background: linear-gradient(45deg, #88ebfa, #f2b3ec, #ffd24f);
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
		}
	}
}
.subtotal {
	margin-top: 16px;
	background: #22262c;
	border-radius: 6px;
	overflow: hidden;
	.tbody {
		height: calc(100% - 60px);
		overflow: hidden;
		overflow-y: auto;
	}
	.tr,
	.th {
		display: flex;
		gap: 0 4px;
		justify-content: space-between;
		font-family: PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #ffffff;
		min-height: 36px;
		padding: 2px 0;
		align-items: center;
		text-align: center;
		> div {
			flex: 1;
		}
	}
	.th {
		background: #151515;
		> div > span {
			background: linear-gradient(0deg, #ff6318 0%, #ff9b13 47%, #ffd30d 100%);

			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
		}
	}
	.tr {
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #999999;
	}

	.tr:nth-child(odd) {
		background: #1a1a1a;
	}
	.tr:hover {
		background: #3a91ff50;
		color: #fff;
		transition: 300ms;
		box-shadow: 0 0 4px rgba(58, 145, 255, 0.3);
		.table_btn > span {
			color: #fff;
		}
	}

	.none {
		display: flex;
		flex-direction: column;
		height: fit-content;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		user-select: none;
		color: #999;
		padding-bottom: 40px;
		img {
			width: 120px;
			margin-bottom: 10px;
		}
	}
}

.btn_box {
	display: flex;
	gap: 10px;
}
.input_box {
	background: #151515;
	box-shadow: 0 0 4px 0px #4e4e4e;
	// background: #262626;
	border-radius: 6px;
	padding: 10px;
	margin-top: 4px;
	input {
		width: 100%;
		height: 100%;
		background: none;
		border: none;
		font-size: 14px;
		color: #fff;
		text-align: center;
	}

	&.readonly {
		background: #15151587;
	}
}
.label_title {
	margin-top: 28px;
}
.label_title_tow {
	margin-top: 10px;
	// margin-bottom: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.label_title_right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 10px;
	gap: 10px;
	font-size: 14px;
	font-family: PingFang-SC-Medium;
	font-weight: normal;
	font-stretch: normal;
	line-height: 18px;
	letter-spacing: 0px;
	color: #999999;
}
.right_info_icon {
	width: 14px;
	height: 14px;
	position: absolute;
	top: 10px;
	right: 4px;
	z-index: 1;
}
.logo_abs {
	position: absolute;
	width: 50px;
	right: 10px;
	top: 10px;
}

.dialog1{
	height: 200px !important;
    min-height: auto !important;
}

.icon_box{
	display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;

	>div{
		width: 40px;
		height: 40px;
		
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.copyright{
	text-align: center;
	font-size: 14px;
	color: #999;
	margin-top: 20px;
	margin-bottom: -80px;
}

.total_power{
	margin-left:auto;
	font-size: 14px;
	span{
		
		background: linear-gradient(45deg, #88ebfa, #f2b3ec, #ffd24f);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}
}

.unit{
	background: linear-gradient(45deg, #88ebfa, #f2b3ec, #ffd24f);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;

	font-size: 12px;
    margin: 0 4px;
}
</style>
