import Web3 from 'web3';
import int2hex from '../utils/int2Hex';
const bigInt = require('big-integer');
import ABI from '../../abi/ABI.json';
import ERC20ABI from '../../abi/ERC20ABI.json';
import ERC1155 from '../../abi/ERC1155.json';
import NftPledge from '../../abi/NftPledge.json';

import { Notify } from 'vant-green';
import { Dialog,Toast } from 'vant';
import i18n from './i18n.js';
// 3t主网
const mainContract = '0x976D2FDB0ab6b6534A6db11eFE0043C044e22F11';
const assetPackage = "0xf5FbE9e651bE4B598fcacEAEfCA36Af252EB1223";
const nftID = 1;
const targetChainId = int2hex(81188, 1)
const chainName = '3TChat';
const chainRPC = 'https://mainnet.3tdao.xyz:8443';
const blockExplorerUrls = 'https://scan.3tdao.xyz';

var ethereum = null;
var web3 = null;
if (window.ethereum) {
    ethereum = window.ethereum;
    web3 = new Web3(window.ethereum);
} else if (window.web3) {
    ethereum = window.web3.currentProvider;
    web3 = new Web3(window.web3.currentProvider);
} else {
    Toast(i18n.t('methods.t1'));
}

window.addEventListener('unhandledrejection', event => {
    console.log('ERROR', event);
});

// 监听账户变化事件
ethereum.on('accountsChanged', (accounts) => {
    // Notify({
    //     type: 'danger',
    //     message: accounts[0],
    // })
    if (accounts.length > 0) {
        if(accounts[0] != localStorage.getItem("userAccount")){
            localStorage.setItem("userAccount", accounts[0]);
            localStorage.removeItem('token');
            window.location.reload();
        }
    }
});
// 监听网络变化事件
ethereum.on('chainChanged', (chainId) => {
    console.log(chainId)
});

export async function checkNetWork() {
    const chainId = await ethereum.request({
        method: 'eth_chainId'
    })
    return chainId == targetChainId;
}
//切换网络
export async function switchNetwork() {
    try {
        // 切换至目标网络
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: targetChainId }],
        });
    } catch (error) {
        // Notify({ type: 'danger', message: JSON.stringify(error) });
        if (error.code === 4902) {
            // 如果目标网络不存在，添加网络
            await addNetwork();
        } else {
            this.userAccount = '';
            localStorage.setItem("userAccount", '');
            Toast(i18n.t('methods.t2'));
        }
    }
}
async function addNetwork() {
    if (window.ethereum) {
        const networkData = {
            chainId: targetChainId, // 网络链 ID，必须是十六进制
            chainName: chainName, // 网络名称
            nativeCurrency: {
                name: '3TC', // 本地代币名称
                symbol: '3TC', // 本地代币符号
                decimals: 18, // 本地代币小数点位数
            },
            rpcUrls: [chainRPC], // RPC 节点 URL 列表
            blockExplorerUrls: [blockExplorerUrls], // 区块浏览器 URL 列表
        };
        try {
            // 请求 MetaMask 添加网络
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [networkData],
            });
            console.log('网络添加成功');
        } catch (addError) {
            console.error('添加网络时出错', addError);
        }
    } else {
        console.error('钱包未安装');
    }
}
//获取账户
export async function getAccount() {
    // 获取用户账户
    return await ethereum.request({ method: 'eth_requestAccounts' });
}
// 封装连接钱包的逻辑
export async function connectWallet() {
    //获取第一次是否有账户
    var account = localStorage.getItem("userAccount");
    const chainId = await ethereum.request({
        method: 'eth_chainId'
    })
    if (chainId != targetChainId) {
        try {
            // 切换至目标网络
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: targetChainId }],
            });

            // 获取用户账户
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            this.userAccount = accounts[0];
            localStorage.setItem("userAccount", accounts[0]);

            window.location.reload();
        } catch (error) {
            if (error.code === 4902) {
                // 如果目标网络不存在，添加网络
                await addNetwork();
            } else {
                this.userAccount = '';
                localStorage.setItem("userAccount", '');
                Toast(i18n.t('methods.t2'));
            }
        }
    } else {
        // 获取用户账户
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        this.userAccount = accounts[0];
        localStorage.setItem("userAccount", accounts[0]);
    }

    if (!this.userAccount) {
        Dialog({
            title: Toast(i18n.t('methods.t3')),  // 未获取到钱包账户
            message: Toast(i18n.t('methods.t4')),    // 请刷新页面重新签名授权
            confirmButtonText: Toast(i18n.t('methods.t5')),  // 刷新页面
            confirmButtonColor: '#FFAB09',
            cancelButtonText: Toast(i18n.t('methods.t6')),   // 关闭Dapp
            showCancelButton: true,
        }).then(() => {
            //刷新页面
            window.location.reload();
        }).catch(() => {
            //关闭整个页面
            window.close()
            return;
        });
    } else {
        //判断账户是否变化
        if (account != this.userAccount) {
            this.$emit('custom-event', this.userAccount);
        }
    }

    

    return this.userAccount;
}
// 重写拉起签名消息
export async function signMessage(address) {
    return await web3.eth.personal.sign(web3.utils.utf8ToHex(address), address, "")
}
//获取用户账户地址
export async function getUserAccount() {
    try {
        // 获取用户账户
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        this.userAccount = accounts[0];
        localStorage.setItem("userAccount", accounts[0]);
    } catch (error) {
        console.log(error)
    }
}

//检查地址是否正确
export async function isAddress(address) {
    return await web3.utils.isAddress(address);
}
export function fromWei(amount) {
    return web3.utils.fromWei(amount, 'ether');
}
//添加代币到钱包
export async function addTokenToWallet(token) {
    this.addTokenLoading=true;
    try {
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: token,
                    symbol: 'POW',
                    decimals: 9,
                    image: window.location.origin+'/favicon.ico',
                },
            },
        });
        if (wasAdded) {
            Toast(i18n.t('methods.t7'));    // 代币添加成功
        } else {
            Toast(i18n.t('methods.t8'));    // 用户拒绝添加
        }
        this.addTokenLoading=false;
    } catch (error) {
        this.addTokenLoading=false;
        Toast(i18n.t('methods.t9'), error); // 添加代币时出错
    }
}

//加密地址
export function encryptAddress(address) {
    return web3.utils.keccak256(address);
}

//查询ERC20可用余额
export async function getBalance(token) {
    try {
        //链接被授权代币合约
        const TokenContract = new web3.eth.Contract(ERC20ABI, token);
        const rawTokens = await TokenContract.methods.balanceOf(localStorage.getItem("userAccount")).call();
        return Web3.utils.fromWei(rawTokens, 'Gwei');
    } catch (error) {
        console.log(error);
        return 0;
    }
}

//充值
export async function addLpNumber(number,config) {
    //把充值的数量转换为wei
    var amount = Web3.utils.toWei(number, 'Gwei');
    //查询是否授权额度
    const TokenContract = new web3.eth.Contract(ERC20ABI, config.Token);
    var rawTokens = await TokenContract.methods.allowance(localStorage.getItem("userAccount"), config.assetPackage).call();

    amount = Number(amount)
    rawTokens = Number(rawTokens)
    //查询目前gas价格
    const gasPrice = await web3.eth.getGasPrice();
    if (rawTokens < amount) {
        //授权额度不足,调用授权
        const approveRes = await approve(config.Token,config.assetPackage,amount);
        if(!approveRes || !approveRes.status){
            return false;
        }
    }
    //链接被授权代币合约
    const Contract = new web3.eth.Contract(ABI, config.assetPackage);
    return new Promise((resolve, reject) => {
        Contract.methods.addLpNumber(localStorage.getItem("userAccount"),amount).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
            gasPrice: bigInt(gasPrice).add(200000000).toString()    //给gasPrice+0.3Gwei加快速度
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        })
    })
}

//授权
async function approve(token,to,amount) {
    try {
        const Contract = new web3.eth.Contract(ERC20ABI, token);
        //查询目前gas价格
        const gasPrice = await web3.eth.getGasPrice();
        const result = await Contract.methods.approve(to,amount).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
            gasPrice: bigInt(gasPrice).add(200000000).toString()    //给gasPrice+0.3Gwei加快速度
        });
        return result;
    }
    catch (error) {
        Notify({ type: 'danger', message: "Approve failed" });
    }
}

//查询lp数据
export async function getLpData() {
    try {
        const token = this.$store.state.config.assetPackage;
        const Contract = new web3.eth.Contract(ABI, token);
        const address = localStorage.getItem("userAccount");
        const getLpInfo = await Contract.methods.getLpInfo(address).call();
        return {
            lpNumber: Number(Web3.utils.fromWei(getLpInfo[0], 'Gwei')),
            lpMintNumber: Number(Web3.utils.fromWei(getLpInfo[1], 'Gwei')),
            topNumber: Number(Web3.utils.fromWei(getLpInfo[2], 'Gwei')),
        }
    }
    catch (error) {
        console.log(error);
    }
}

//提现
export async function convertToLpToken(to,amount,token) {
    amount = Web3.utils.toWei(amount, 'Gwei');
    try {
        const Contract = new web3.eth.Contract(ABI, token);
        //查询目前gas价格
        const gasPrice = await web3.eth.getGasPrice();
        const result = await Contract.methods.convertToLpToken(to,amount).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
            gasPrice: bigInt(gasPrice).add(200000000).toString()    //给gasPrice+0.3Gwei加快速度
        });
        return result;
    }
    catch (error) {
        console.log(error);
        Notify({ type: 'danger', message: "Withdraw failed" });
        throw error;
    }
}

//查詢NFT信息
export async function getNftInfo() {
    const address = localStorage.getItem("userAccount");
    const nft_token = this.$store.state.config.nft;
    const pledge_contract = this.$store.state.config.nftstaking;
    //查詢餘額
    const NFTContract = new web3.eth.Contract(ERC1155, nft_token);
    //查詢內容（nft餘額）
    const nftBalance = await NFTContract.methods.balanceOf(address, nftID).call();

    //查詢質押數
    const PledgeContract = new web3.eth.Contract(NftPledge, pledge_contract);
    const pledgeBalance = await PledgeContract.methods.getUserStakeAmount(address).call();

    //查詢收益
    const income = await PledgeContract.methods.earned(address).call();
    return {nftBalance:Number(nftBalance), pledgeBalance:Number(pledgeBalance), income:Number(income)};
}

//質押NFT
export async function pledgeNft(amount) {
    const address = localStorage.getItem("userAccount");
    const nft_token = this.$store.state.config.nft;
    const pledge_contract = this.$store.state.config.nftstaking;
    //授權NFT
    const NFTContract = new web3.eth.Contract(ERC1155, nft_token);
    //先查詢是否授權
    const isApproved = await NFTContract.methods.isApprovedForAll(address, pledge_contract).call();
    let approveRes = 1;
    if(!isApproved){
        const res = await NFTContract.methods.setApprovalForAll(pledge_contract, true).send({
            from: localStorage.getItem("userAccount")
        });
        approveRes = res.status
    }
    if(approveRes){
        //質押NFT
        const PledgeContract = new web3.eth.Contract(NftPledge, pledge_contract);
        const pledgeRes = await PledgeContract.methods.stake(amount).send({
            from: localStorage.getItem("userAccount")
        });
        if(pledgeRes.status){
            Notify({ type: 'success', message: this.$t('pledgeNft.t8') });
            return true;
        }else{
            Notify({ type: 'danger', message: this.$t('pledgeNft.t9') });
        }
    }else{
        Notify({ type: 'danger', message: this.$t('pledgeNft.t7') });
    }

    return false;
}

//撤回質押
export async function withdrawNft(amount) {
    const pledge_contract = this.$store.state.config.nftstaking;
    //撤回質押
    const PledgeContract = new web3.eth.Contract(NftPledge, pledge_contract);
    const pledgeRes = await PledgeContract.methods.withdraw(amount).send({
        from: localStorage.getItem("userAccount")
    });
    if(pledgeRes.status){
        Notify({ type: 'success', message: this.$t('pledgeNft.t10') });
        return true;
    }else{
        Notify({ type: 'danger', message: this.$t('pledgeNft.t11') });
    }
    return false;
}

//領取收益
export async function claimReward() {
    const pledge_contract = this.$store.state.config.nftstaking;
    //撤回質押
    const PledgeContract = new web3.eth.Contract(NftPledge, pledge_contract);
    const pledgeRes = await PledgeContract.methods.claimReward().send({
        from: localStorage.getItem("userAccount")
    });
    if(pledgeRes.status){
        Notify({ type: 'success', message: this.$t('pledgeNft.t12') });
        return true;
    }else{
        Notify({ type: 'danger', message: this.$t('pledgeNft.t13') });
    }
    return false;
}

//查詢總算力
export async function totalPower() {
    try {
        const token = this.$store.state.config.assetPackage;
        const Contract = new web3.eth.Contract(ABI, token);
        const totalPower = await Contract.methods.totalPower().call();
        return Number(Web3.utils.fromWei(totalPower, 'Gwei'))/10;
    }
    catch (error) {
        console.log(error);
        return 0;
    }
}